<template>
  <!-- 查看指令详情 -->
  <gc-dialog
    title="指令详情"
    class="directive-detail-dialog layout-dialog"
    :show.sync="innerVisible"
    :append-to-body="true"
    @close="innerVisible = false"
  >
    <el-form ref="form" :model="{}" label-position="top">
      <el-form-item
        v-for="item in commandDetailData"
        :key="item.key"
        :label="item.label"
      >
        <json-viewer
          v-if="item.label === '指令内容'"
          :value="item.value"
          :expanded="true"
          :expand-depth="5"
        ></json-viewer>
        <el-input
          v-else
          v-model="item.value"
          placeholder="自动填充"
          disabled
        ></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="innerVisible = false" class="btn-close"
        >关 闭</el-button
      >
    </template>
  </gc-dialog>
</template>

<script>
import JsonViewer from "vue-json-viewer";

export default {
  name: "commandDetail",
  components: { JsonViewer },
  props: {
    visible: {}, //弹窗
    commandDetailData: {},
  },
  data() {
    return {};
  },
  computed: {
    innerVisible: {
      get: function () {
        return this.visible;
      },
      set: function (val) {
        this.$emit("update:visible", val);
      },
    },
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
@import "../runLayout.scss";
::v-deep {
  .jv-container {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    max-height: 250px;
    overflow: auto;
  }
  .jv-container .jv-code {
    padding: 0;
  }
  .jv-code,
  .jv-code.open {
    padding-bottom: 0;
    padding: 0 15px;
  }
  .jv-node.jv-key-node {
    line-height: 21px;
  }
}
</style>
