// 防抖
function debounce(fn, t) {
  const delay = t || 300;
  let timer;
  return (...args) => {
    if (timer) {
      clearTimeout(timer);
    }
    const context = this;
    timer = setTimeout(() => {
      timer = null;
      fn.apply(context, args);
    }, delay);
  };
}

class ScaleLayout {
  constructor({
    context,
    width,
    height,
    container,
    propName,
    mainEl,
    layerEl,
    adpeterClass,
    resizeListenter,
  }) {
    this.styleEl = null;
    this.scale = 1; // 默认初始缩放1
    this.context = context;
    this.width = width; // 设计稿宽度
    this.height = height; // 设计稿高度
    this.container = container || document.body;
    this.propName = propName || "scale";
    this.mainEl = mainEl;
    this.layerEl = layerEl;
    this.adpeterClass = adpeterClass || "adpter-area";
    this.resizeListenter = resizeListenter || (() => {});
    this.dealLayout();
    this.setScale();
    this.resizeListenter(this.scale);
    this.listen();
  }

  // 处理布局
  dealLayout() {
    // 处理main区域
    this.mainEl.style = `
      width: calc(${this.width}px * var(--${this.propName}));
      height: calc(${this.height}px * var(--${this.propName}));
    `;
    // 处理图层
    this.layerEl.style = `
     width: ${this.width}px;
     height: ${this.height}px;
     transform: scale(var(--${this.propName})) translate(-50%,-50%);
     transform-origin:0 0;
    `;

    // 动态生成适配类
    this.styleEl = document.createElement("style");

    this.styleEl.innerHTML = `
     .${this.adpeterClass} {
      transform: scale(var(--${this.propName}));
      transform-origin:0 0;
     }
    `;
    this.container.append(this.styleEl);
  }

  // 页面生命周期及浏览器大小监听
  listen() {
    this.onresize = debounce(() => {
      this.setScale();
      this.resizeListenter(this.scale);
    }, 300);
    window.addEventListener("resize", this.onresize);

    this.context.$on("hook:beforeDestroy", () => {
      window.removeEventListener("resize", this.onresize);
      this.styleEl && this.container.removeChild(this.styleEl);
    });
  }

  // 设置缩放
  dealScale() {
    const ws = window.innerWidth / this.width;
    const hs = window.innerHeight / this.height;
    return ws < hs ? ws : hs;
  }
  //  获取scale值
  getScale() {
    return this.scale;
  }
  // 设置scale
  setScale() {
    this.scale = this.dealScale();
    this.container.style.setProperty(`--${this.propName}`, this.scale);
  }
}

export default ScaleLayout;
