<template>
  <div class="directive-record" v-loading="loading">
    <div class="filter">
      <gc-custom-search
        required
        class="search"
        width="500px"
        key-word="指令日期"
        type="date"
        date-picker-type="datetimerange"
        :search.sync="params['first']"
        :clearable="false"
      >
        <template v-slot:icon>
          <i class="iconfont icon-riqi"></i>
        </template>
      </gc-custom-search>
      <gc-custom-search
        class="search"
        key-word="指令类型"
        type="select"
        width="300px"
        :search.sync="params['cmdClass']"
        :search-option="cmdOptions"
        :searchOptionKey="{
          label: 'name',
          value: 'defaultValue',
        }"
        needAllForSearch
      ></gc-custom-search>
      <gc-custom-search
        v-if="tenantType == 1"
        class="search"
        key-word="指令状态"
        type="select"
        width="300px"
        :search.sync="params['status']"
        :search-option="statusOptions"
        :searchOptionKey="{
          label: 'name',
          value: 'defaultValue',
        }"
        needAllForSearch
      ></gc-custom-search>
      <el-button v-click-blur type="primary" class="query" @click="query"
        >查 询</el-button
      >
      <el-button v-click-blur @click="reset">重 置</el-button>
      <el-button
        v-click-blur
        type="warning"
        plain
        class="query"
        v-if="tabData.deviceTypeId === 810 && tabData.subDeviceId"
        @click="securityCheckResult"
        >安检结果</el-button
      >
    </div>
    <div class="table">
      <gc-table
        ref="directvieTable"
        pagination-type="special"
        border
        showPage
        :columns="columns"
        :table-data="tableData"
        :page-params="pageParams"
        :current-page.sync="pageParams.currentIdOffset"
        @current-page-change="pageChange"
      ></gc-table>
    </div>
    <Command-Detail
      :visible.sync="showDirectiveDetail"
      :command-detail-data="directiveDetailInfo"
    ></Command-Detail>
  </div>
</template>

<script>
import { apiGetCmdmodelList, apiReSend } from "@/api/account";
import CommandDetail from "@/views/runManage/directiveDetail/CommandDetail";
import { isBlank } from "@/utils/validate";

export default {
  name: "DirectiveRecord",
  components: { CommandDetail },
  props: {
    curTabName: String,
    tabData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
      params: {
        first: null,
        iotDeviceId: null,
        limit: 10,
        cmdClass: null,
        status: null,
      },
      tableData: [],
      pageParams: {
        hasNext: null, // 取接口返回
        idOffset: null, // 取接口返回
        currentIdOffset: null, // 取自当前请求的参数
      },
      showDirectiveDetail: false,
      directiveDetailInfo: [
        {
          key: "deviceNo",
          label: "设备编号",
          value: "",
        },
        {
          key: "detail",
          label: "指令内容",
          value: {},
        },
      ],
    };
  },
  computed: {
    cmdOptions() {
      return this.$store.getters.dataList.cmdClass || [];
    },
    statusOptions() {
      return this.$store.getters.dataList.commandStatus || [];
    },
    tenantType() {
      return this.$store.getters.userInfo.tenantType;
    },
    columns() {
      let arr = [
        {
          key: "cmdName",
          name: "功能名称",
        },
        {
          key: "status",
          name: "指令状态",
          render: (h, row) => {
            let statusMap = {
              0: { class: "dot-warning", text: "待执行" },
              1: { class: "dot-success", text: "成功" },
              2: { class: "dot-error", text: "失败" },
            };
            let val = row.status;
            return h(
              "span",
              { class: isBlank(val) ? "" : statusMap[val]["class"] },
              isBlank(val) ? "--" : statusMap[val]["text"]
            );
          },
        },
        {
          key: "cmdClass",
          name: "指令类型",
          minWidth: 110,
        },
        {
          key: "ts",
          name: "创建日期",
          minWidth: 170,
        },
        {
          key: "endTs",
          name: "结束日期",
          minWidth: 170,
        },
        {
          key: "cmdUserInfo",
          name: "操作员",
        },
        {
          key: "cmdErrorCodeMsg",
          name: "错误详情",
          tenantType: "1",
        },
        {
          key: "operate",
          name: "操作",
          minWidth: this.tenantType == 1 ? 180 : 120,
          fixed: "right",
          render: (h, row) => {
            let style = {
              "font-size": "14px",
              "font-family": "SourceHanSansCN-Regular, SourceHanSansCN",
              "font-weight": "400",
              color: "#4A69FF",
              "line-height": "21px",
              "margin-right": "20px",
              cursor: "pointer",
            };
            let ops = [
              h(
                "span",
                {
                  on: {
                    click: () => this.directiveDetail(row),
                  },
                  style,
                },
                "指令详情"
              ),
            ];
            if (
              this.tenantType == 1 &&
              row.status == 2 &&
              row.msgSource === "iot" &&
              this.$has("monitor:device:account:command:singleresend")
            ) {
              ops.push(
                h(
                  "span",
                  {
                    on: {
                      click: () => this.reSend(row),
                    },
                    style,
                  },
                  "指令补发"
                )
              );
            }
            return h("div", {}, ops);
          },
        },
      ];

      return arr.filter(
        (item) =>
          isBlank(item.tenantType) || item.tenantType === this.tenantType
      );
    },
  },
  watch: {
    curTabName: {
      immediate: true,
      handler(val) {
        if (val === "DirectiveRecord" && !this.tableData.length) {
          this.reset();
        }
      },
    },
  },
  methods: {
    getDirectiveList(idOffset) {
      if (!this.params.first) {
        this.$message.warning("指令日期不可为空");
        return;
      }
      this.loading = true;
      this.params.iotDeviceId = this.tabData.iotDeviceId;
      let obj = { idOffset };
      for (var key in this.params) {
        let val = this.params[key];
        if (this.params[key]) {
          if (key === "first") {
            obj["startTime"] = val[0].getTime();
            obj["endTime"] = val[1].getTime();
          } else {
            obj[key] = val;
          }
        }
      }
      apiGetCmdmodelList(obj)
        .then((res) => {
          this.tableData = res.records || [];
          this.pageParams.hasNext = res.hasNext;
          this.pageParams.idOffset = res.idOffset;
          this.pageParams.currentIdOffset = idOffset;
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    query() {
      this.getDirectiveList("");
    },
    reset() {
      this.params = this.$options.data().params;
      let startTime = new Date(
        new Date(new Date().toLocaleDateString()).getTime()
      ); // 当天0点
      let endTime = new Date(
        new Date(new Date().toLocaleDateString()).getTime() +
          24 * 60 * 60 * 1000 -
          1
      ); // 当天23:59
      let ago = new Date(startTime - 86400000 * 7);
      let now = endTime;
      this.params.first = [ago, now];
      this.query();
    },
    securityCheckResult() {
      const { subDeviceId, subDeviceTypeId } = this.tabData;
      this.$router.push({
        name: "deviceDetail",
        query: {
          deviceId: subDeviceId,
          deviceType: subDeviceTypeId,
        },
        params: {
          name: "RemoteSecurityCheck",
        },
      });
    },
    pageChange(val) {
      this.getDirectiveList(val);
    },
    directiveDetail(row) {
      this.directiveDetailInfo.map((item) => {
        item.value = row[item.key];
      });
      this.showDirectiveDetail = true;
    },
    reSend(row) {
      this.$confirm("你确定要补发指令吗？").then(() => {
        this.loading = true;
        apiReSend({
          cmdId: row.cmdId,
        })
          .then(() => {
            this.$message.success("指令补发成功");
            this.query();
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.directive-record {
  @include base-button(80px);
  padding: 0 24px;
  height: 100%;
  display: flex;
  flex-direction: column;
  .filter {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 20px 0 24px;
    .query {
      margin-left: 20px;
    }
    .search {
      margin-right: 20px;
    }
  }
  .table {
    flex: 1;
    height: 0;
  }
}
.dialog-content {
  @include base-button(80px, 32px, 16px);
  .title {
    font-size: 14px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 600;
    color: #222222;
    margin: 20px 0 10px;
  }
}
</style>
