import request from "./request";

export const apiGetCustomerList = (data) => {
  return request({
    url: "/monitor/ledger/user/list",
    method: "POST",
    data,
  });
};

export const apiGetDeviceListFromCustomer = (params) => {
  return request({
    url: "/monitor/ledger/user/device/list",
    method: "GET",
    params,
  });
};

export const apiGetAnalyzeUseGas = (data) => {
  return request({
    url: "/monitor/ledger/analysis/use/gas",
    method: "POST",
    data,
  });
};

export const apiUpdateCustomerInfo = (data) => {
  return request({
    url: "/monitor/ledger/user/update",
    method: "POST",
    data,
  });
};
