<template>
  <div class="dialog-uninstall">
    <el-form
      ref="uninstallForm"
      :rules="rules"
      :model="form"
      label-width="80px"
      label-position="top"
    >
      <el-form-item prop="uninstallDate" label="拆除日期">
        <el-date-picker
          v-model="form.uninstallDate"
          type="date"
          placeholder="请选择"
        >
        </el-date-picker>
      </el-form-item>
      <!-- <el-form-item prop="uninstallName" label="拆除人员">
        <el-autocomplete
          class="inline-input"
          v-model="form.uninstallName"
          :fetch-suggestions="querySearch"
          placeholder="请填写"
          :trigger-on-focus="false"
          @select="handleSelect"
        ></el-autocomplete>
      </el-form-item> -->
      <el-form-item prop="uninstallReason" label="拆除原因">
        <el-input
          v-model="form.uninstallReason"
          type="textarea"
          placeholder="请填写"
          maxlength="64"
          resize="none"
          show-word-limit
          :autosize="{ minRows: 2, maxRows: 3 }"
          @input="uninstallReasonChange"
        >
        </el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "dialogUninstall",
  components: {},
  props: {
    params: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      personList: [],
      timeout: null,
      rules: {
        uninstallDate: [
          { required: true, message: "请选择拆除日期", trigger: "blur" },
        ],
        uninstallName: [
          { required: true, message: "请输入拆除人员", trigger: "blur" },
        ],
        uninstallReason: [
          { required: true, message: "请输入拆除原因", trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    form: {
      get() {
        return this.params;
      },
      set(val) {
        this.$emit("update:params", val);
      },
    },
  },
  methods: {
    uninstallReasonChange(e) {
      this.form.uninstallReason = e.replace(/[, ]/g, "");
    },
    querySearch(queryString, cb) {
      var personList = this.personList;
      var results = queryString
        ? personList.filter(this.createFilter(queryString))
        : personList;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog-uninstall {
}
</style>
