var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"use-gas"},[_c('el-form',{ref:"useGasForm",attrs:{"model":_vm.params,"label-width":"80px","label-position":"top"}},[_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":12}},[(_vm.showCascader)?_c('el-form-item',{attrs:{"prop":"addressCode","rules":[
            {
              validator: _vm.adressCodeRule,
              trigger: '',
            } ]}},[_c('el-cascader',{attrs:{"placeholder":"请选择(必填)","options":_vm.locationOptions,"disabled":_vm.addressParams.addressName !== null,"popper-class":"gc-customer-cascader"},on:{"change":_vm.handleChange},model:{value:(_vm.params.addressCode),callback:function ($$v) {_vm.$set(_vm.params, "addressCode", $$v)},expression:"params.addressCode"}})],1):_vm._e()],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"prop":"addressPollName","rules":[
            {
              max: 64,
              message: '小区名称不可超过64个字符',
              trigger: '',
            } ]}},[_c('el-autocomplete',{staticClass:"inline-input",attrs:{"placeholder":"请输入街道/小区/乡镇/村庄(非必填)","value-key":"name","fetch-suggestions":_vm.querySearch,"disabled":_vm.addressParams.addressName !== null || !_vm.areaCode,"trigger-on-focus":true},on:{"select":_vm.handleSelect},model:{value:(_vm.params.addressPollName),callback:function ($$v) {_vm.$set(_vm.params, "addressPollName", $$v)},expression:"params.addressPollName"}})],1)],1),_c('el-col',{staticStyle:{"margin-top":"10px","margin-bottom":"10px"},attrs:{"span":24}},[_c('el-form-item',{attrs:{"prop":"address","rules":[
            {
              required: _vm.required,
              message: '请输入详细地址',
              trigger: '',
            },
            {
              max: 128,
              message: '详细地址不可超过128个字符',
              trigger: '',
            } ]}},[_c('el-input',{attrs:{"placeholder":"请输入详细地址(必填)","disabled":_vm.addressParams.addressName !== null},on:{"input":_vm.paramsAddressChange},model:{value:(_vm.params.address),callback:function ($$v) {_vm.$set(_vm.params, "address", $$v)},expression:"params.address"}})],1)],1),_c('el-col',{attrs:{"span":24}},[_c('div',{staticClass:"address-list"},[_c('div',{staticClass:"title"},[_vm._v("地址选择：")]),_c('el-radio-group',{on:{"change":_vm.addressChange},model:{value:(_vm.addressParams.addressName),callback:function ($$v) {_vm.$set(_vm.addressParams, "addressName", $$v)},expression:"addressParams.addressName"}},[_c('el-radio',{attrs:{"label":null}},[_vm._v("自定义地址")]),_vm._l((_vm.addressList),function(item){return _c('el-radio',{key:item.addressName,attrs:{"label":item.addressName}},[_vm._v(_vm._s(item.addressName))])})],2)],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }