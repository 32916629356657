<template>
  <gc-dialog
    title="指令详情"
    width="45%"
    :show.sync="visible"
    @cancel="visible = false"
    @close="visible = false"
    :append-to-body="true"
    :show-footer="false"
  >
    <div class="content">
      <div id="pressureEchart" class="echart" v-if="pressureList.length"></div>
      <gc-empty v-else></gc-empty>
    </div>
  </gc-dialog>
</template>

<script>
const echarts = require("echarts/lib/echarts");
require("echarts/lib/chart/line");
require("echarts/lib/component/grid");
require("echarts/lib/component/tooltip");
require("echarts/lib/component/visualMap");
require("echarts/lib/component/dataZoom");
require("echarts/lib/component/markLine");
require("echarts/lib/component/markPoint");
require("echarts/lib/component/legend");
require("echarts/lib/component/title");

export default {
  name: "PressureChart",
  props: {
    pressureList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      visible: true,
    };
  },
  created() {
    this.$nextTick(this.renderCharts);
  },
  methods: {
    renderCharts() {
      this.chartInstance = echarts.init(
        document.getElementById("pressureEchart")
      );
      const option = {
        grid: {
          left: "50px",
          right: "60px",
          top: "60px",
          bottom: "60px",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.pressureList.map((o) => o.time),

          axisTick: {
            show: false,
            lineStyle: {
              color: "#fff",
            },
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            rotate: 20,
          },
        },
        yAxis: {
          type: "value",
          name: `单位：${this.pressureUnitName}`,
          nameGap: 30,
          scale: true,
          axisLabel: {
            formatter: (value) => {
              if (value) return value.toFixed(0);
              return 0;
            },
          },
        },
        series: [
          {
            name: `压力（${this.pressureUnitName}）`,
            type: "line",
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "rgba(77, 107, 255, 0.8)" },
                { offset: 1, color: "rgba(77, 107, 255, 0)" },
              ]),
            },
            smooth: true,
            symbol: "none",
            data: this.pressureList.map((o) => o.value),
            markPoint: {
              symbolSize: 60,
              data: [
                { type: "max", name: "Max", itemStyle: { color: "#4269DF" } },
                { type: "min", name: "Min", itemStyle: { color: "#4269DF" } },
              ],
            },
            lineStyle: {
              color: "#4269DF",
            },
          },
        ],
      };
      this.chartInstance.setOption(option);
    },
  },
  computed: {
    pressureUnitName() {
      return this.$store.getters.pressureUnitName;
    },
  },
  watch: {
    visible(val) {
      if (!val) {
        this.$emit("close-modal");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  height: 450px;
  @include flex-center;
  .echart {
    width: 100%;
    height: 100%;
  }
  .gc-blank-page {
    margin: auto;
  }
}
</style>
