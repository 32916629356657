<template>
  <gc-table
    :showPage="false"
    border
    :columns="columns"
    :table-data="tableData"
  ></gc-table>
</template>

<script>
import { apiGetDeviceRuleConfig } from "@/api/account.js";

export default {
  name: "DeviceRule",
  props: {
    tabData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      tableData: [],
      columns: [
        {
          key: "functionSet",
          name: "功能集",
        },
        {
          key: "functionName",
          name: "功能名称",
        },
        {
          key: "configurationValue",
          name: "默认值",
        },
        {
          key: "valueToBeEffective",
          name: "待生效值",
        },
        {
          key: "currentValue",
          name: "设备当前值",
        },
        {
          key: "unitName",
          name: "单位",
        },
      ],
    };
  },
  computed: {
    configRuleUpdate() {
      return this.$store.state.account.configRuleUpdate;
    },
  },
  watch: {
    tabData: {
      handler(val) {
        if (Object.keys(val).length > 0) {
          this.getDeviceRuleList();
        }
      },
      immediate: true,
    },
    configRuleUpdate(val) {
      if (val) {
        this.getDeviceRuleList();
        this.$store.commit("account/SET_CONFIG_RULE_UPDATE", false);
      }
    },
  },
  methods: {
    getDeviceRuleList() {
      const { deviceId } = this.tabData;
      apiGetDeviceRuleConfig({
        deviceId,
      }).then((res) => {
        this.tableData = res.records || [];
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
