<template>
  <div class="header">
    <div class="box-time">
      <div class="time">{{ dateDay }}</div>
      <div class="date">
        {{ dateWeek }}
        <p class="day">{{ dateYear }}</p>
      </div>
    </div>
    <img
      class="img-title"
      src="@/assets/images/screen/iot-title.png"
      alt="IOT安全监控大屏"
    />
    <div v-if="isOnlyBigScreen" class="entry" @click="handleLogout">
      退出登录
    </div>
    <div v-else class="entry" @click="handleEntrySystem">点击进入操作系统</div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { clearLS } from "@/utils/storage";

export default {
  name: "Header",
  data() {
    return {
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      weekday: [
        "星期日",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六",
      ],
      timer: null,
    };
  },
  computed: {
    ...mapGetters({
      routes: "app/routes",
    }),
    isOnlyBigScreen() {
      return (
        this.routes.length === 1 && this.routes[0].fullPath === "/big-screen"
      );
    },
  },
  mounted() {
    this.getCurrentTime();
    this.timer = setInterval(() => {
      this.getCurrentTime();
    }, 1000);
  },
  methods: {
    getCurrentTime() {
      const date = this.dayjs(new Date());
      this.dateDay = date.format("HH:mm:ss");
      this.dateYear = date.format("YYYY-MM-DD");
      this.dateWeek = date.format(this.weekday[date.day()]);
    },
    // 进入操作系统
    handleEntrySystem() {
      const routes = [...this.routes];
      const hasHomeAuth = routes.find((r) => r?.fullPath === "/");
      // 如果菜单权限包含首页则跳转首页
      if (hasHomeAuth) {
        this.$router.push("/");
      } else {
        // 否则跳转至除大屏路由外的第一个菜单路由
        const otherRoutes = routes.filter((r) => r?.fullPath !== "/big-screen");
        const { fullPath } = this.getFirstChild(otherRoutes);
        this.$router.push(fullPath);
      }
    },
    getFirstChild(list) {
      if (list[0].children?.length) return this.getFirstChild(list[0].children);
      return list[0];
    },
    // 退出登录
    handleLogout() {
      clearLS();
      location.reload();
    },
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
};
</script>
<style scoped lang="scss">
.header {
  position: absolute;
  top: 0;
  left: 10px;
  right: 10px;
  height: 74px;
  background: url("~@/assets/images/screen/bg-header.png") no-repeat;
  background-size: cover;
  background-position: center;
  font-family: PangMenZhengDao-3;
  font-weight: 600;
  color: #80a4ed;
  .box-time {
    position: absolute;
    left: 20px;
    top: 34px;
    display: flex;
    align-items: center;
    .time {
      font-size: 28px;
      width: 150px;
    }
    .date {
      font-size: 16px;
      .day {
        margin-top: 4px;
        font-size: 12px;
      }
    }
  }
  .img-title {
    width: 316px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .entry {
    cursor: pointer;
    position: absolute;
    right: 5%;
    top: 35px;
    font-size: 16px;
    border-bottom: 1px solid #80a4ed;
    padding-bottom: 8px;
  }
}
</style>
