<template>
  <div class="alarm-record" v-loading="loading">
    <div class="filter">
      <gc-custom-search
        class="search"
        width="500px"
        key-word="告警日期"
        type="date"
        date-picker-type="datetimerange"
        :search.sync="params['first']"
      >
        <template v-slot:icon>
          <i class="iconfont icon-riqi"></i>
        </template>
      </gc-custom-search>
      <gc-custom-search
        class="search"
        width="300px"
        key-word="告警时序"
        type="select"
        required
        :search.sync="params['alarmSeq']"
        :search-option="options['alarmSeq']"
      ></gc-custom-search>
      <gc-custom-search
        class="search"
        width="300px"
        key-word="告警等级"
        type="select"
        needAllForSearch
        :search.sync="params['alarmLevel']"
        :search-option="alarmLevelOptions"
        :search-option-key="{ label: 'name', value: 'defaultValue' }"
      ></gc-custom-search>
      <gc-custom-search
        class="search"
        width="300px"
        key-word="告警分类"
        type="select"
        needAllForSearch
        :search.sync="params['alarmTypeId']"
        :search-option="alarmTypeOptions"
        :search-option-key="{ label: 'name', value: 'defaultValue' }"
      ></gc-custom-search>
      <el-button v-click-blur type="primary" class="query" @click="query"
        >查 询</el-button
      >
      <el-button v-click-blur @click="reset">重 置</el-button>
    </div>
    <div class="table">
      <gc-table
        ref="alarmTable"
        showPage
        border
        :columns="columns"
        :table-data="tableData"
        :total="total"
        :current-page.sync="currentPage"
        @current-page-change="pageChange"
      ></gc-table>
    </div>
    <el-dialog
      :title="dialogTitle"
      :visible.sync="showDetailStatusDialog"
      :append-to-body="true"
      width="45%"
    >
      <EchartTable
        ref="echartTable"
        :open-type="openType"
        :info="info"
        :show="showDetailStatusDialog"
        :default-time="defaultTime"
      ></EchartTable>
    </el-dialog>

    <alarm-handle-dialog
      :show.sync="showHandleDialog"
      :alarmId="alarmId"
      @ops-success="getAlarmList(currentPage)"
    ></alarm-handle-dialog>
  </div>
</template>

<script>
var imgSrc = require("@/assets/images/icon/alarm.svg");

import { apiGetAlarmList } from "@/api/account";
import { isBlank } from "@/utils/validate";
import EchartTable from "@/views/deviceManage/detail/components/ChartTable.vue";
import AlarmHandleDialog from "@/components/AlarmHandleDialog/index.vue";
import alarmMix from "@/views/runManage/alarmRecords/mixin.js";
import { nameTrans } from "@/utils";

export default {
  name: "alarmRecord",
  components: { EchartTable, AlarmHandleDialog },
  mixins: [alarmMix],
  props: {
    curTabName: String,
  },
  data() {
    return {
      loading: false,
      total: 0,
      currentPage: 1,
      params: {
        first: null,
        alarmSeq: "0",
        alarmLevel: null,
        current: 1,
        deviceId: null,
        size: 10,
        alarmTypeId: null,
      },
      options: {
        alarmSeq: [
          {
            label: "实时",
            value: "0",
          },
          {
            label: "历史",
            value: "1",
          },
        ],
      },
      tableData: [],
      showHandleDialog: false,
      alarmId: "",
      currentAlarmSeq: "0",
    };
  },
  computed: {
    alarmLevelOptions() {
      return this.$store.getters.dataList.alarmLevel;
    },
    alarmTypeOptions() {
      return this.$store.getters.dataList.alarmType;
    },
    // 告警状态
    alarmStatus() {
      return this.$store.getters.dataList.alarmStatus || [];
    },
    columns() {
      let arr = [
        {
          key: "alarmName",
          name: "告警名称",
        },
        {
          key: "alarmLevel",
          name: "告警等级",
          render: (h, row) => {
            let svgList = [];
            let alarmText = {
              1: "一般",
              2: "中等",
              3: "严重",
            };
            for (var i = 1; i <= row.alarmLevel; i++) {
              svgList.push(
                h("img", {
                  attrs: { src: imgSrc },
                  style: {
                    width: "14px",
                    height: "14px",
                    "margin-left": "6px",
                  },
                })
              );
            }
            let val = row.alarmLevel;
            return h(
              "div",
              {
                style: {
                  display: "flex",
                  color: "#EC6B60",
                  "font-size": "14px",
                  "justify-content": "flex-start",
                  "align-items": "center",
                },
              },
              isBlank(val)
                ? "--"
                : [...[h("span", {}, alarmText[val])], ...[svgList]]
            );
          },
        },
        {
          key: "alarmType",
          name: "告警分类",
        },
        {
          key: "createTime",
          name: "告警时间",
          width: "160",
        },
        {
          key: "alarmStatus",
          name: "告警状态",
          render: (h, row) => {
            let val = row.alarmStatus;
            const text = nameTrans(val, this.alarmStatus);
            return h("span", {}, isBlank(val) ? "--" : text);
          },
        },
        {
          key: "alarmAckStatus",
          name: "告警处理结果",
          render: (h, row) => {
            let val = row.alarmAckStatus;
            const map = {
              0: "未处理",
              1: "已处理",
            };
            return h("span", {}, isBlank(val) ? "--" : map[val]);
          },
        },
        {
          key: "oprate",
          name: "操作",
          width: "250px",
          fixed: "right",
          render: (h, row) => {
            // 根据告警状态进行动态变化
            if (
              this.currentAlarmSeq !== "0" &&
              row.isPressHigh !== 1 &&
              ![39, 40, 55, 56, 57].includes(row.eventId)
            ) {
              return h("span", { style: { color: "#CCCCCC" } }, "/");
            }
            let style = {
              "font-size": "14px",
              "font-family": "SourceHanSansCN-Regular, SourceHanSansCN",
              "font-weight": "400",
              color: "#4A69FF",
              "line-height": "21px",
              "margin-right": "20px",
              cursor: "pointer",
            };
            return h("div", {}, [
              this.currentAlarmSeq === "0"
                ? h(
                    "span",
                    {
                      on: {
                        click: () => this.markDealed(row.alarmId),
                      },
                      style,
                    },
                    "处理登记"
                  )
                : "",
              ...[
                [39, 40, 55, 56, 57].includes(row.eventId)
                  ? h(
                      "span",
                      {
                        on: {
                          click: () => {
                            let params = {
                              ...row,
                              deviceId: this.$attrs.common,
                            };
                            this.showDetailStatus(params);
                          },
                        },
                        style,
                      },
                      "数据展示"
                    )
                  : [],
              ],
              row.isPressHigh === 1
                ? h(
                    "span",
                    {
                      on: {
                        click: () => {
                          let params = {
                            ...row,
                            deviceId: this.params.deviceId,
                          };
                          this.showPressure(params);
                        },
                      },
                      style,
                    },
                    "压力查看"
                  )
                : "",
            ]);
          },
        },
      ];
      return this.getColumns(arr);
    },
  },
  watch: {
    curTabName: {
      immediate: true,
      handler(val) {
        if (val === "AlarmRecord" && !this.tableData.length) {
          this.reset();
        }
      },
    },
  },
  methods: {
    getAlarmList(page) {
      if (isBlank(this.params.alarmSeq)) {
        this.$message.warning("告警时序不能为空");
        return;
      }
      this.loading = true;
      this.params.deviceId = this.$attrs.common;
      this.params.current = page;
      let obj = {};
      console.log(this.params);
      for (var key in this.params) {
        let val = this.params[key];
        if (this.params[key]) {
          if (key === "first") {
            obj["alarmStartTime"] = this.dayjs(val[0]).format(
              "YYYY-MM-DD HH:mm:ss"
            );
            obj["alarmEndTime"] = this.dayjs(val[1]).format(
              "YYYY-MM-DD HH:mm:ss"
            );
          } else {
            obj[key] = val;
          }
        }
      }
      apiGetAlarmList(obj)
        .then((res) => {
          this.currentAlarmSeq = this.params.alarmSeq;
          this.tableData = res.records || [];
          this.total = res.total;
          this.currentPage = res.current;
          this.$emit("qury-count");
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    query() {
      this.getAlarmList(1);
    },
    pageChange(page) {
      this.getAlarmList(page);
    },
    reset() {
      this.params.first = null;
      this.params.alarmSeq = "0";
      this.params.alarmLevel = null;
      this.params.alarmTypeId = null;
      this.query();
    },
    markDealed(alarmId) {
      this.alarmId = alarmId;
      this.showHandleDialog = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.alarm-record {
  @include base-button(80px);
  padding: 0 24px;
  height: 100%;
  display: flex;
  flex-direction: column;
  .filter {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 20px 0 24px;
    .query {
      margin-left: 20px;
    }
    .search {
      margin-right: 20px;
    }
  }
  .table {
    flex: 1;
    height: 0;
  }
}
</style>
