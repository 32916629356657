<template>
  <gc-dialog
    title="修改客户信息"
    ref="dialogBox"
    width="480px"
    :show.sync="visible"
    @ok="handleSubmit"
    @cancel="$emit('close-modal')"
    @close="$emit('close-modal')"
  >
    <el-form
      ref="updateCustomerRef"
      :rules="rules"
      :model="form"
      label-width="80px"
      label-position="top"
    >
      <el-form-item prop="userName" label="客户名称">
        <el-input v-model="form.userName" placeholder="请输入"> </el-input>
      </el-form-item>
      <el-form-item prop="phone" label="联系电话">
        <el-input v-model="form.phone" placeholder="请输入"> </el-input>
      </el-form-item>
      <el-form-item prop="homeType" label="是否独居老人">
        <el-radio-group v-model="form.homeType">
          <el-radio :label="1">是</el-radio>
          <el-radio :label="0">否</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
  </gc-dialog>
</template>

<script>
import { ruleRequired, RULE_PHONE } from "@/utils/rules";
import { apiUpdateCustomerInfo } from "@/api/customerManage";
export default {
  name: "UpdateCustomerModal",
  components: {},
  props: {
    params: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      visible: true,
      form: {
        userName: "",
        phone: "",
        homeType: "",
      },
      rules: {
        userName: [ruleRequired("请输入客户名称")],
        phone: [
          this.params.userType === 2 ? ruleRequired("请输入联系电话") : null,
          RULE_PHONE,
        ].filter(Boolean),
        homeType: [ruleRequired("请选择是否独居老人")],
      },
    };
  },

  created() {
    const { userId, userName, phone, homeType } = this.params;
    Object.assign(this.form, {
      userId,
      userName,
      phone,
      homeType,
    });
  },

  methods: {
    handleSubmit() {
      this.$refs.updateCustomerRef.validate(async (valid) => {
        console.log(valid);
        if (valid) {
          await apiUpdateCustomerInfo(this.form);
          this.$message.success("更新客户信息成功");
          this.$emit("close-modal", true);
        }
      });
    },
  },
};
</script>
