<template>
  <div class="bs-box">
    <el-scrollbar>
      <div class="container">
        <slot />
      </div>
    </el-scrollbar>
    <div class="border-bottom-icon"></div>
  </div>
</template>
<script>
export default {
  name: "BsBox",
};
</script>
<style scoped lang="scss">
@mixin bs-box-common($url) {
  content: "";
  position: absolute;
  width: 28px;
  height: 29px;
  background: url($url);
  background-size: cover;
}
.bs-box {
  .container {
    padding: 0 20px;
  }
  &::before {
    top: -2px;
    left: -2px;
    @include bs-box-common("~@/assets/images/screen/border1.png");
  }
  &::after {
    top: -2px;
    right: -2px;
    @include bs-box-common("~@/assets/images/screen/border2.png");
  }
  .border-bottom-icon {
    &::before {
      bottom: -2px;
      left: -2px;
      @include bs-box-common("~@/assets/images/screen/border3.png");
    }
    &::after {
      bottom: -2px;
      right: -2px;
      @include bs-box-common("~@/assets/images/screen/border4.png");
    }
  }
}
::v-deep .el-scrollbar {
  height: 100%;
  .el-scrollbar__wrap {
    overflow-x: hidden;
  }
}
</style>
