<template>
  <div class="directive-record" v-loading="loading">
    <div class="filter">
      <gc-custom-search
        required
        class="search"
        width="500px"
        key-word="指令日期"
        type="date"
        date-picker-type="datetimerange"
        :search.sync="params['searchTime']"
        :clearable="false"
        valueFormat="yyyy-MM-dd HH:mm:ss"
      >
        <template v-slot:icon>
          <i class="iconfont icon-riqi"></i>
        </template>
      </gc-custom-search>
      <el-button v-click-blur type="primary" class="query" @click="query"
        >查 询</el-button
      >
      <el-button v-click-blur @click="reset">重 置</el-button>
    </div>
    <div class="table">
      <gc-table
        ref="directvieTable"
        pagination-type="special"
        border
        showPage
        :columns="columns"
        :table-data="tableData"
        :page-params="pageParams"
        :current-page.sync="pageParams.current"
        @current-page-change="pageChange"
      ></gc-table>
    </div>
    <PressureChart
      v-if="pressureVisible"
      :pressureList="pressureList"
      @close-modal="pressureVisible = false"
    />
  </div>
</template>

<script>
import { apiGetRemoteSecurityCheckList } from "@/api/account";
import PressureChart from "../../components/PressureChart";
export default {
  name: "RemoteSecurityCheck",
  components: { PressureChart },
  props: {
    curTabName: String,
    tabData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
      pressureVisible: false,
      params: {
        searchTime: [
          this.dayjs(new Date()).format("YYYY-MM-DD") + " 00:00:00",
          this.dayjs(new Date()).format("YYYY-MM-DD") + " 23:59:59",
        ],
      },
      columns: [
        {
          key: "createTime",
          name: "创建日期",
          minWidth: 170,
        },
        {
          key: "detectionResult",
          name: "设备状态",
          render: (h, row) => {
            return (
              <div
                className={
                  row.detectionResult === "1" ? "dot-success" : "dot-error"
                }
              >
                {row.detectionResult === "1" ? "正常" : "泄露"}
              </div>
            );
          },
        },
        {
          key: "operate",
          name: "操作",
          render: (h, row) => {
            let style = {
              "font-size": "14px",
              "font-family": "SourceHanSansCN-Regular, SourceHanSansCN",
              "font-weight": "400",
              color: "#4A69FF",
              "line-height": "21px",
              "margin-right": "20px",
              cursor: "pointer",
            };
            return h("div", {}, [
              h(
                "span",
                {
                  on: {
                    click: () => this.directiveDetail(row),
                  },
                  style,
                },
                "指令详情"
              ),
            ]);
          },
        },
      ],
      tableData: [],
      pageParams: {
        current: 1,
        size: 20,
        total: 0,
      },
      pressureList: [],
    };
  },
  methods: {
    getDirectiveList() {
      this.loading = true;
      const searchParams = {
        deviceId: this.tabData.deviceId,
        startTime: this.params.searchTime[0],
        endTime: this.params.searchTime[1],
        current: this.pageParams.current,
        size: this.pageParams.size,
      };
      apiGetRemoteSecurityCheckList(searchParams)
        .then((res) => {
          this.tableData = res.records || [];
          this.pageParams.current = res.current;
          this.pageParams.size = res.size;
          this.pageParams.total = res.total;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    query() {
      this.getDirectiveList();
    },

    reset() {
      this.params.searchTime = [
        this.dayjs(new Date()).format("YYYY-MM-DD") + " 00:00:00",
        this.dayjs(new Date()).format("YYYY-MM-DD") + " 23:59:59",
      ];
      this.query();
    },

    pageChange(val) {
      this.pageParams.current = val;
      this.getDirectiveList();
    },

    directiveDetail(row) {
      console.log(row);
      console.log(
        this.dayjs("2022-12-12 09:00:00")
          .add(10, "s")
          .format("YYYY-MM-DD HH:mm:ss")
      );
      const pressureDataList = row.pressureData.split(",");
      for (let i = 0; i < 60; i++) {
        this.pressureList[i] = {
          time: this.dayjs(row.startingTime.substr(0, 19))
            .add(10 * i, "s")
            .format("YYYY-MM-DD HH:mm:ss")
            .substr(11, 8),
          value: pressureDataList[i] || 0,
        };
      }
      this.pressureVisible = true;
    },
  },

  watch: {
    tabData: {
      handler(val) {
        if (val.deviceId) {
          this.getDirectiveList();
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.directive-record {
  @include base-button(80px);
  padding: 0 24px;
  height: 100%;
  display: flex;
  flex-direction: column;
  .filter {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 20px 0 24px;
    .query {
      margin-left: 20px;
    }
    .search {
      margin-right: 20px;
    }
  }
  .table {
    flex: 1;
    height: 0;
  }
}
.dialog-content {
  @include base-button(80px, 32px, 16px);
  .title {
    font-size: 14px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 600;
    color: #222222;
    margin: 20px 0 10px;
  }
}
</style>
