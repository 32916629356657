<template>
  <div class="relation-device" v-loading="loading">
    <div class="filter">
      <div class="add-relation-device">
        <el-button v-click-blur @click="relationShow = true"
          >新增关联设备</el-button
        >
      </div>
    </div>
    <div class="table">
      <gc-table
        ref="operateTable"
        border
        :showPage="false"
        :columns="columns"
        :table-data="tableData"
      ></gc-table>
    </div>
    <!-- 新增关联设备/关联设备详情 -->
    <relation-device-dialog
      :show.sync="relationShow"
      :tabData="tabData"
      @refresh="getRelactionDeviceList"
    />
  </div>
</template>

<script>
import {
  apiGetRelationDeviceList,
  apiDeleteRelationDevice,
} from "@/api/account";
import { isBlank } from "@/utils/validate";
import RelationDeviceDialog from "./RelationDeviceDialog.vue";

export default {
  name: "RelationDevice",
  components: { RelationDeviceDialog },
  props: {
    curTabName: String,
    tabData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
      columns: [
        {
          key: "subDeviceCategory",
          name: "关联设备",
          render: (h, row) => {
            return h(
              "span",
              {},
              row.subDeviceCategory == 0 ? "设备" : "压力点"
            );
          },
        },
        {
          key: "subDeviceTypeName",
          name: "设备类型名称",
        },
        {
          key: "subDeviceNo",
          name: "设备编号",
        },
        {
          key: "model",
          name: "型号",
        },
        {
          key: "manufacturerName",
          name: "厂商名称",
        },
        {
          key: "installDate",
          name: "安装日期",
        },
        {
          key: "communicateTime",
          name: "通讯日期",
        },
        {
          key: "ops",
          name: "操作",
          fixed: "right",
          width: 180,
          render: (h, row) => {
            let style = {
              "font-size": "14px",
              "font-family": "SourceHanSansCN-Regular, SourceHanSansCN",
              "font-weight": "400",
              color: "#4A69FF",
              "line-height": "21px",
              cursor: "pointer",
            };
            return h("div", {}, [
              // 设备（流量计）可跳转详情
              row.subDeviceCategory == 0
                ? h(
                    "span",
                    {
                      style: {
                        ...style,
                        "margin-right": "20px",
                      },
                      on: {
                        click: () => {
                          this.$router.push({
                            name: "deviceDetail",
                            query: {
                              deviceId: row.subDeviceId,
                              deviceType: row.subDeviceTypeId,
                            },
                            params: {
                              reload: true,
                            },
                          });
                        },
                      },
                    },
                    "查看详情"
                  )
                : "",
              h(
                "span",
                {
                  style,
                  on: {
                    click: () => {
                      this.$confirm("您确定要解除关联设备吗？").then(() => {
                        apiDeleteRelationDevice({
                          gatewayDeviceId: this.tabData?.deviceId,
                          subChannelNo: row.subChannelNo,
                        }).then(() => {
                          this.$message.success("解除成功！");
                          // 设备变化
                          this.$store.commit(
                            "account/SET_RELATION_DEVICE_CHANGE",
                            true
                          );
                          this.getRelactionDeviceList();
                        });
                      });
                    },
                  },
                },
                "解除"
              ),
            ]);
          },
        },
      ],
      tableData: [],
      relationShow: false,
    };
  },
  computed: {},
  watch: {
    curTabName: {
      immediate: true,
      handler(val) {
        if (val === "RelationDevice") {
          this.getRelactionDeviceList();
        }
      },
    },
  },
  methods: {
    getRelactionDeviceList() {
      this.loading = true;
      apiGetRelationDeviceList({
        gatewayDeviceId: this.tabData?.deviceId,
      })
        .then((res) => {
          this.tableData = res || [];
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.relation-device {
  @include base-button(120px);
  padding: 0 24px 24px 24px;
  height: 100%;
  display: flex;
  flex-direction: column;
  .filter {
    margin: 20px 0 24px;
    .add-relation-device {
      .el-button {
        border-color: #4d6bff;
        color: #4d6bff;
      }
    }
  }
  .table {
    flex: 1;
    height: 0;
  }
}
</style>
