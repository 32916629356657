<template>
  <div class="customer-info-modal">
    <div class="basic-info">
      <div class="header">
        <div class="avatar">
          <img src="@/assets/images/icon/avatar.png" alt="" />
        </div>
        <div class="name">
          <h5>
            <span>{{ currentDeviceInfo.userName }}</span>
            <em v-if="currentDeviceInfo.homeTypeName">{{
              currentDeviceInfo.homeTypeName
            }}</em>
            <img
              v-has="'monitor:ledger:user:update'"
              @click="updateVisible = true"
              src="@/assets/images/icon/edit.svg"
              alt=""
            />
          </h5>
          <p>用户号：{{ info.userNo || "--" }}</p>
        </div>
      </div>
      <div class="label-form">
        <div class="label-form-item">
          <div class="label-form-item__title">用户类型：</div>
          <div class="label-form-item__value">
            {{ currentDeviceInfo.userTypeName || "--" }}
          </div>
        </div>
        <div class="label-form-item">
          <div class="label-form-item__title">联系电话：</div>
          <div class="label-form-item__value">
            {{ currentDeviceInfo.phone || "--" }}
          </div>
        </div>
      </div>
    </div>
    <div class="relation-devices" v-loading="loading">
      <h3>关联设备</h3>
      <div class="list">
        <div
          class="list-item"
          v-for="item in relationDeviceList"
          :key="item.iotDeviceId"
          :class="{
            selected: item.iotDeviceId === currentDeviceInfo.iotDeviceId,
          }"
          @click="handleDeviceRowClick(item)"
        >
          <div class="title_n">
            <img src="@/assets/images/icon/rsq.svg" alt="" class="icon" />
            <p class="name" @click.stop="handleDeviceNameClick(item)">
              {{ item.deviceTypeName }}
            </p>
            <em class="tag" :class="{ offline: item.isOnline !== 1 }">{{
              item.isOnline === 1 ? "在线" : "离线"
            }}</em>
          </div>
          <div class="label-form">
            <div class="label-form-item">
              <div class="label-form-item__title">编号：</div>
              <div class="label-form-item__value">{{ item.deviceNo }}</div>
            </div>
            <div class="label-form-item" v-if="item.gasDeviceNo">
              <div class="label-form-item__title">条形编码：</div>
              <div class="label-form-item__value">{{ item.gasDeviceNo }}</div>
            </div>
            <div class="label-form-item">
              <div class="label-form-item__title">设备地址：</div>
              <div class="label-form-item__value">{{ item.addressName }}</div>
            </div>
          </div>
          <div class="alarm-list" @click.stop="handleNavigatorAlarmList(item)">
            <div
              class="alarm-list-item"
              v-for="(itemName, idx) in item.alarmList"
              :key="idx"
            >
              <div class="desc">
                <p>{{ itemName.alarmName }}</p>
              </div>
              <div class="time">{{ itemName.createTime }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <UpdateCustomer
      v-if="updateVisible"
      @close-modal="closeModal"
      :params="currentDeviceInfo"
    />
  </div>
</template>

<script>
import { userType } from "@/config/const";
import { apiGetDeviceListFromCustomer } from "@/api/customerManage";
import UpdateCustomer from "./UpdateCustomer.vue";
export default {
  name: "CustomerInfoModal",
  components: { UpdateCustomer },
  props: {
    info: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      userType,
      relationDeviceList: [],
      currentDeviceInfo: {},
      updateVisible: false,
      loading: false,
    };
  },

  methods: {
    async findDeviceListFromCutomer(userNo) {
      this.loading = true;
      try {
        const data = await apiGetDeviceListFromCustomer({ userNo });
        this.relationDeviceList = data;
        const t = this.relationDeviceList.find(
          (o) => o.userId === this.currentDeviceInfo.userId
        );
        this.currentDeviceInfo = t || this.relationDeviceList[0];
      } finally {
        this.loading = false;
      }
    },

    handleDeviceRowClick(deviceItem) {
      this.currentDeviceInfo = deviceItem;
    },

    handleDeviceNameClick({ deviceId, deviceTypeId }) {
      this.$router.push({
        path: "/device/detail",
        query: {
          deviceId,
          deviceType: deviceTypeId,
          from: "custom",
        },
      });
    },

    handleNavigatorAlarmList({ deviceId, deviceTypeId }) {
      if (!this.$has("monitor:device:account:record:alarm"))
        return this.$message.error("您没有操作权限！");
      this.$router.push({
        name: "deviceDetail",
        query: {
          deviceId,
          deviceType: deviceTypeId,
          from: "custom",
        },
        params: {
          name: "AlarmRecord",
        },
      });
    },

    closeModal(refresh) {
      this.updateVisible = false;
      if (refresh) {
        this.findDeviceListFromCutomer(this.info.userNo);
      }
    },
  },

  watch: {
    info: {
      handler(newVal) {
        this.findDeviceListFromCutomer(newVal.userNo);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.customer-info-modal {
  width: 19vw;
  padding: 12px 12px 0;
  border-radius: 4px;
  border: 1px solid #f1f1f1;
  height: 100%;
  display: flex;
  flex-direction: column;
  .basic-info {
    border-bottom: 1px solid #f1f1f1;
    padding-bottom: 8px;
    .header {
      display: flex;
      padding: 12px;
      .name {
        margin-left: 12px;
        flex: 1;
        h5 {
          display: flex;
          align-items: center;
          span {
            color: #282c42;
            font-size: 20px;
            font-weight: 600;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          em {
            background: #f2f6ff;
            color: #3f435e;
            font-size: 14px;
            line-height: 21px;
            padding: 2px 8px;
            display: inline-block;
            border-radius: 4px;
            position: relative;
            top: 1px;
            margin-left: 6px;
            flex-shrink: 0;
          }
          img {
            margin-left: auto;
            cursor: pointer;
          }
        }
        p {
          color: #9a9cb0;
          font-size: 14px;
          margin-top: 4px;
        }
      }
    }
  }
  .relation-devices {
    margin-top: 18px;
    overflow-y: scroll;
    flex: 1;
    height: 0;
    h3 {
      position: relative;
      color: #3f435e;
      font-size: 18px;
      font-weight: 600;
      padding-left: 10px;
      &:after {
        content: "";
        position: absolute;
        width: 4px;
        height: 16px;
        background-color: #4a69ff;
        left: 0;
        top: 3px;
      }
    }
    .list {
      margin-top: 18px;
      &-item {
        background-color: #fbfcff;
        border-radius: 6px;
        border: 1px solid #f1f1f1;
        padding: 12px 12px 8px;
        cursor: pointer;
        &.selected {
          background-color: #e8efff;
          border-color: #567ced;
        }
        &:not(:last-child) {
          margin-bottom: 12px;
        }
        .title_n {
          display: flex;
          align-items: center;
          .name {
            color: #4e4e4e;
            font-weight: 500;
            font-size: 16px;
            margin-left: 8px;
          }
          .tag {
            margin-left: auto;
            background-color: #16c972;
            color: #fff;
            font-size: 12px;
            border-radius: 12px;
            padding: 3px 8px 4px 8px;
            flex-shrink: 0;
            &.offline {
              background-color: #aaabbd;
            }
          }
        }
        .label-form {
          margin-top: 12px;
        }
        .alarm-list {
          margin-top: 8px;
          &-item {
            margin-bottom: 6px;
            background-color: #fff1f1;
            padding: 12px;
            border-radius: 4px;
            .desc {
              display: flex;
              p {
                color: #ed5672;
                font-size: 14px;
                font-weight: 700;
                line-height: 24px;
                &.status {
                  margin-left: auto;
                }
              }
            }
            .time {
              color: #f592a4;
              font-size: 12px;
              margin-top: 4px;
            }
          }
        }
      }
    }
  }
  .label-form {
    padding: 0 8px;
    &-item {
      display: flex;
      padding: 6px 0;
      &__title {
        color: #9a9cb0;
      }
      &__value {
        color: #5f627d;
        width: 0;
        flex: 1;
        text-align: right;
        line-height: 1.2;
      }
    }
  }
}
</style>
