<template>
  <div id="barChart" class="bar-chart" />
</template>
<script>
const echarts = require("echarts/lib/echarts");
require("echarts/lib/chart/bar");
require("echarts/lib/component/grid");
require("echarts/lib/component/tooltip");
import { insertStr } from "@/utils";

export default {
  name: "BarChart",
  props: {
    chartData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      options: {
        grid: {
          left: "10%",
          right: "0",
          top: "20%",
          bottom: "20px",
        },
        legend: {
          data: ["安装", "拆除"],
          itemWidth: 6,
          itemHeight: 3,
          top: 0,
          right: 0,
          itemGap: 18,
          textStyle: {
            color: "#fff",
            fontSize: 13,
          },
        },
        xAxis: {
          data: [],
          axisTick: { show: false },
          axisLabel: {
            color: "#fff",
            formatter: (value) => {
              const month = value.split("-")[1];
              return `${+month}月`;
            },
          },
        },
        yAxis: {
          type: "value",
          name: "台",
          nameTextStyle: { align: "right", color: "#fff" },
          nameGap: 20,
          axisLabel: {
            color: "#fff",
            formatter: (value) => {
              if (value) return value.toFixed(0);
              return 0;
            },
          },
          axisLine: { show: true },
          splitLine: {
            show: true,
            lineStyle: {
              color: ["rgba(45, 60, 92, 0.3)"],
            },
          },
          splitNumber: 4,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            lineStyle: {
              opacity: 0,
            },
          },
        },
        series: [
          {
            name: "安装",
            type: "bar",
            stack: "one",
            data: [],
            itemStyle: { color: "#00a8ff" },
            barMaxWidth: 12,
          },
          {
            name: "拆除",
            type: "bar",
            stack: "two",
            data: [],
            itemStyle: { color: "#00D7E9" },
            barMaxWidth: 12,
          },
        ],
      },
    };
  },
  watch: {
    chartData: {
      handler(val) {
        console.log(val);
        this.updateChart();
      },
      deep: true,
    },
  },
  methods: {
    updateChart() {
      const chartDom = document.getElementById("barChart");
      const barChart = echarts.init(chartDom);
      const dateList = [];
      const installList = [];
      const uninstallList = [];
      for (let i = 0, len = this.chartData.length; i < len; i++) {
        const { date, installCount, uninstallCount } = this.chartData[i];
        const newDate = insertStr(date, 4, "-");
        dateList.push(newDate);
        installList.push(installCount);
        uninstallList.push(uninstallCount);
      }
      this.options.xAxis.data = dateList;
      this.options.series[0].data = installList;
      this.options.series[1].data = uninstallList;
      barChart.clear();
      barChart.setOption(this.options);
      addEventListener("resize", function () {
        barChart.resize();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.bar-chart {
  margin-top: 20px;
  width: 100%;
  height: 200px;
}
</style>
