<template>
  <div class="use-gas">
    <el-form
      ref="useGasForm"
      :model="params"
      label-width="80px"
      label-position="top"
    >
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item
            v-if="showCascader"
            prop="addressCode"
            :rules="[
              {
                validator: adressCodeRule,
                trigger: '',
              },
            ]"
          >
            <el-cascader
              v-model="params.addressCode"
              placeholder="请选择(必填)"
              :options="locationOptions"
              :disabled="addressParams.addressName !== null"
              @change="handleChange"
              popper-class="gc-customer-cascader"
            ></el-cascader>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            prop="addressPollName"
            :rules="[
              {
                max: 64,
                message: '小区名称不可超过64个字符',
                trigger: '',
              },
            ]"
          >
            <el-autocomplete
              class="inline-input"
              v-model="params.addressPollName"
              placeholder="请输入街道/小区/乡镇/村庄(非必填)"
              value-key="name"
              :fetch-suggestions="querySearch"
              :disabled="addressParams.addressName !== null || !areaCode"
              :trigger-on-focus="true"
              @select="handleSelect"
            ></el-autocomplete>
          </el-form-item>
        </el-col>
        <el-col style="margin-top: 10px; margin-bottom: 10px" :span="24">
          <el-form-item
            prop="address"
            :rules="[
              {
                required: required,
                message: '请输入详细地址',
                trigger: '',
              },
              {
                max: 128,
                message: '详细地址不可超过128个字符',
                trigger: '',
              },
            ]"
          >
            <el-input
              v-model="params.address"
              placeholder="请输入详细地址(必填)"
              :disabled="addressParams.addressName !== null"
              @input="paramsAddressChange"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <div class="address-list">
            <div class="title">地址选择：</div>
            <el-radio-group
              v-model="addressParams.addressName"
              @change="addressChange"
            >
              <el-radio :label="null">自定义地址</el-radio>
              <el-radio
                v-for="item in addressList"
                :key="item.addressName"
                :label="item.addressName"
                >{{ item.addressName }}</el-radio
              >
            </el-radio-group>
          </div>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import {
  apiGetCityList,
  apiGetDistrictList,
  apiGetAddressPoll,
  apiGetAddressList,
} from "@/api/account";
import { mapActions } from "vuex";

export default {
  name: "useGas",
  components: {},
  props: {
    form: {
      type: Object,
      default: () => {},
    },
    required: {
      type: Boolean,
      default: false,
    },
    locationOptions: {
      type: Array,
      default: () => [],
    },
    additionalInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    const adressCodeRule = (rule, value, callback) => {
      if (value && !value[2]) {
        return callback(new Error("请选择所在省市的区"));
      }
      callback();
    };
    return {
      addressList: [],
      addressParams: {
        address: null,
        addressPollName: null,
        addressPollId: null,
        addressCode: [
          this.additionalInfo.province,
          this.additionalInfo.city,
          "",
        ],
        areaCode: null,
        addressName: null,
      },
      showCascader: true,
      adressCodeRule,
    };
  },
  computed: {
    params: {
      get() {
        return this.form;
      },
      set(val) {
        this.$emit("update:form", val);
      },
    },
    areaCode() {
      return this.params.addressCode?.[2];
    },
  },
  methods: {
    ...mapActions({
      getProvinceList: "apiCache/getProvinceList",
    }),
    paramsAddressChange(e) {
      this.params.address = e.replace(/[, ]/g, "");
    },
    getProvinceCityAndDistrict(node, resolve) {
      console.log(node);
      const { level } = node;
      switch (level) {
        case 0:
          this.getProvinceList().then((res) => {
            const records = res.map((item) => {
              return {
                value: item.regionCode,
                label: item.regionName,
                leaf: level >= 2,
              };
            });

            resolve(records);
          });
          break;
        case 1:
          apiGetCityList(node.value).then((res) => {
            const records = res.records.map((item) => {
              return {
                value: item.regionCode,
                label: item.regionName,
                leaf: level >= 2,
              };
            });

            resolve(records);
          });
          break;
        case 2:
          apiGetDistrictList(node.value).then((res) => {
            const records = res.records.map((item) => {
              return {
                value: item.regionCode,
                label: item.regionName,
                leaf: level >= 2,
              };
            });

            resolve(records);
          });
          break;
      }
    },
    handleChange(value) {
      if (value && value.length) {
        this.$set(this.params, "areaCode", value[2]);
      }
    },
    querySearch(queryString, cb) {
      if (!this.areaCode) return;
      apiGetAddressPoll({ code: this.areaCode, name: queryString }).then(
        (res) => {
          let list = res.records || [];
          cb(list);
        }
      );
    },
    handleSelect(item) {
      this.$set(this.params, "addressPollId", item.addressPollId);
    },
    renderAddressForm() {
      let obj = this._.cloneDeep(this.addressParams);
      this.showCascader = false;
      this.$nextTick(() => {
        for (var key in obj) {
          this.$set(this.params, key, obj[key]);
        }
        this.showCascader = true;
      });
    },
    addressChange(val) {
      this.addressParams = this._.cloneDeep(this.addressList).filter(
        (item) => item.addressName === val
      )[0] || {
        address: null,
        addressPollName: null,
        addressPollId: null,
        addressCode: [
          this.additionalInfo.province,
          this.additionalInfo.city,
          "",
        ],
        areaCode: null,
        addressName: null,
      };
      this.renderAddressForm();
      this.$emit("requestLocation", this.addressParams);
    },
    getAddressList(params, addressName) {
      apiGetAddressList(params)
        .then((res) => {
          this.addressList = res.addressList || [];
          if (this.addressList.length) {
            if (addressName) {
              this.addressParams = this._.cloneDeep(this.addressList).filter(
                (item) => item.addressName === addressName
              )[0] || {
                address: null,
                addressPollName: null,
                addressPollId: null,
                addressCode: [
                  this.additionalInfo.province,
                  this.additionalInfo.city,
                  "",
                ],
                areaCode: null,
                addressName: null,
              };
            } else {
              this.addressParams = this._.cloneDeep(this.addressList[0]);
            }
          } else {
            this.addressParams = {
              address: null,
              addressPollName: null,
              addressPollId: null,
              addressCode: [
                this.additionalInfo.province,
                this.additionalInfo.city,
                "",
              ],
              areaCode: null,
              addressName: null,
            };
          }
          this.$emit("requestLocation", this.addressParams);
          this.renderAddressForm();
        })
        .catch(() => {})
        .finally(() => {
          this.$emit("update:loading-use-gas", false);
          this.$refs.useGasForm.clearValidate();
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.use-gas {
  .address-list {
    width: calc(100% + 8px);
    padding: 15px 20px 0 20px;
    background-color: #f7f7f7;
    border-radius: 4px;
    max-height: 126px;
    box-sizing: border-box;
    overflow: scroll;
    .title {
      font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #4d4d4d;
      margin-bottom: 15px;
      height: 14px;
    }
  }
  ::v-deep .el-radio-group {
    display: flex;
    flex-direction: column;
    .el-radio {
      margin-bottom: 20px;
    }
  }
}
</style>
<style lang="scss">
.gc-customer-cascader {
  .el-cascader-panel {
    .el-cascader-menu {
      &:nth-child(-n + 2) {
        display: none;
      }
      &:last-child {
        li {
          &:first-child {
            display: none;
          }
        }
      }
    }
  }
}
</style>
