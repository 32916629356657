<template>
  <div class="operate-record" v-loading="loading">
    <div class="filter">
      <gc-custom-search
        class="search"
        key-word="操作类型"
        type="select"
        width="300px"
        needAllForSearch
        :search-option="searchOptions"
        :search.sync="params['operatorType']"
        :search-option-key="{ label: 'name', value: 'defaultValue' }"
      ></gc-custom-search>
      <gc-custom-search
        class="search"
        width="500px"
        key-word="日期区间"
        type="date"
        :search.sync="params['second']"
        date-picker-type="datetimerange"
      >
        <template v-slot:icon>
          <i class="iconfont icon-riqi"></i>
        </template>
      </gc-custom-search>
      <el-button v-click-blur type="primary" class="query" @click="query"
        >查 询</el-button
      >
      <el-button v-click-blur @click="reset">重 置</el-button>
    </div>
    <div class="table">
      <gc-table
        ref="operateTable"
        border
        showPage
        :total="total"
        :columns="columns"
        :table-data="tableData"
        :current-page.sync="currentPage"
        @current-page-change="pageChange"
      ></gc-table>
    </div>
  </div>
</template>

<script>
import { apiGetOperateList } from "@/api/account";
import { isBlank } from "@/utils/validate";

export default {
  name: "OperateRecord",
  components: {},
  props: {
    curTabName: String,
  },
  data() {
    return {
      loading: false,
      total: 0,
      currentPage: 1,
      params: {
        operatorType: null,
        second: null,
        size: 10,
        current: 1,
        deviceId: null,
      },
      columns: [
        {
          key: "deviceNo",
          name: "设备编号",
        },
        {
          key: "operatorType",
          name: "操作类型",
          render: (h, row) => {
            let val = row.operatorType;
            return h(
              "span",
              {},
              isBlank(val)
                ? "--"
                : (
                    this.searchOptions.find(
                      (item) => item.defaultValue == val
                    ) || {}
                  ).name || "--"
            );
          },
        },
        {
          key: "operatorSource",
          name: "操作来源",
          render: (h, row) => {
            let val = row.operatorSource;
            return h(
              "span",
              {},
              isBlank(val)
                ? "--"
                : (
                    this.operatorSourceOptions.find(
                      (item) => item.defaultValue == val
                    ) || {}
                  ).name || "--"
            );
          },
        },
        {
          key: "operatorReason",
          name: "变更原因",
          tooltip: true,
        },
        {
          key: "operatorName",
          name: "创建者",
        },
        {
          key: "createTime",
          name: "时间",
        },
      ],
      tableData: [],
    };
  },
  computed: {
    searchOptions() {
      return this.$store.getters?.dataList?.operatorType || [];
    },
    operatorSourceOptions() {
      return this.$store.getters?.dataList?.operatorSource || [];
    },
  },
  watch: {
    curTabName: {
      immediate: true,
      handler(val) {
        if (val === "OperateRecord" && !this.tableData.length) {
          this.reset();
        }
      },
    },
  },
  methods: {
    getOperateList(current) {
      this.loading = true;
      this.params.deviceId = this.$attrs.common;
      this.params.current = current;
      let obj = {};
      for (var key in this.params) {
        let val = this.params[key];
        if (val) {
          if (key === "second") {
            obj["operatorStartTime"] = this.dayjs(val[0]).format(
              "YYYY-MM-DD HH:mm:ss"
            );
            obj["operatorEndTime"] = this.dayjs(val[1]).format(
              "YYYY-MM-DD HH:mm:ss"
            );
          } else {
            obj[key] = val;
          }
        }
      }
      apiGetOperateList(obj)
        .then((res) => {
          this.tableData = res.records || [];
          this.total = res.total;
          this.currentPage = res.current;
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    query() {
      this.getOperateList(1);
    },
    reset() {
      this.params.operatorType = null;
      this.params.second = null;
      this.getOperateList(1);
    },
    pageChange(current) {
      this.getOperateList(current);
    },
  },
};
</script>
<style lang="scss" scoped>
.operate-record {
  @include base-button(80px);
  padding: 0 24px;
  height: 100%;
  display: flex;
  flex-direction: column;
  .filter {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 20px 0 24px;
    .query {
      margin-left: 20px;
    }
    .search {
      margin-right: 20px;
    }
  }
  .table {
    flex: 1;
    height: 0;
  }
}
</style>
