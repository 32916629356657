<template>
  <div class="collect-record" v-loading="loading">
    <div class="filter">
      <gc-custom-search
        required
        class="search"
        width="500px"
        key-word="采集日期"
        type="date"
        date-picker-type="datetimerange"
        :search.sync="params['first']"
        :clearable="false"
      >
        <template v-slot:icon>
          <i class="iconfont icon-riqi"></i>
        </template>
      </gc-custom-search>
      <el-button v-click-blur type="primary" class="query" @click="query"
        >查 询</el-button
      >
      <el-button v-click-blur @click="reset">重 置</el-button>
    </div>
    <div class="table">
      <gc-table
        ref="collectTable"
        pagination-type="special"
        border
        showPage
        :columns="columns"
        :table-data="tableData"
        :page-params="pageParams"
        :current-page.sync="pageParams.currentIdOffset"
        @current-page-change="pageChange"
      ></gc-table>
    </div>
  </div>
</template>

<script>
import { apiGetCollectList } from "@/api/account";
import { isBlank } from "@/utils/validate";

export default {
  name: "CollectRecord",
  components: {},
  props: {
    tabData: {
      type: Object,
      default: () => {},
    },
    curTabName: String,
  },
  data() {
    return {
      loading: false,
      params: {
        first: null,
        iotDeviceId: null,
        limit: 10,
      },
      tableData: [],
      columnConfig: [],
      pageParams: {
        hasNext: null, // 取接口返回
        idOffset: null, // 取接口返回
        currentIdOffset: null, // 取自当前请求的参数
      },
    };
  },
  computed: {
    columns() {
      return this.columnConfig;
    },
  },
  watch: {
    curTabName: {
      immediate: true,
      handler(val) {
        if (val === "CollectRecord" && !this.tableData.length) {
          this.reset();
        }
      },
    },
  },
  mounted() {},
  methods: {
    getCollectList(idOffset) {
      if (!this.params.first) {
        this.$message.warning("采集日期不可为空");
        return;
      }
      this.loading = true;
      let obj = { idOffset };
      this.params.iotDeviceId = this.tabData.iotDeviceId;
      // this.params.iotDeviceId = "4c2dbaa0-b96a-11ec-871c-af61ac047de3";
      for (var key in this.params) {
        let val = this.params[key];
        if (this.params[key]) {
          if (key === "first") {
            obj["startTime"] = val[0].getTime();
            obj["endTime"] = val[1].getTime();
          } else {
            obj[key] = val;
          }
        }
      }
      apiGetCollectList(obj)
        .then((res) => {
          this.tableData = res.records || [];
          let columnList = res.collectTitles || [];
          this.columnConfig = columnList.map((item) => {
            if (item.unitname) {
              item.name = item.name + `(${item.unitname})`;
            }
            let obj = {
              key: item.attributeKey,
              name: item.name,
              tooltip: true,
            };
            if (item.interactiveTranslationDes) {
              obj.render = (h, row) => {
                let val = row[item.attributeKey];
                return h(
                  "span",
                  {},
                  isBlank(val) ? "--" : item.interactiveTranslationDes[val]
                );
              };
            }
            return obj;
          });
          this.pageParams.hasNext = res.hasNext;
          this.pageParams.idOffset = res.idOffset;
          this.pageParams.currentIdOffset = idOffset;
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    query() {
      this.getCollectList("");
    },
    reset() {
      let startTime = new Date(
        new Date(new Date().toLocaleDateString()).getTime()
      ); // 当天0点
      let endTime = new Date(
        new Date(new Date().toLocaleDateString()).getTime() +
          24 * 60 * 60 * 1000 -
          1
      ); // 当天23:59
      let ago = new Date(startTime - 86400000 * 7);
      let now = endTime;
      this.params.first = [ago, now];
      this.getCollectList("");
    },
    pageChange(idOffset) {
      this.getCollectList(idOffset);
    },
  },
};
</script>
<style lang="scss" scoped>
.collect-record {
  @include base-button(80px);
  padding: 0 24px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  .filter {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 20px 0 24px;
    .query {
      margin-left: 20px;
    }
    .search {
      margin-right: 20px;
    }
  }
  .table {
    flex: 1;
    height: 0;
  }
}
</style>
