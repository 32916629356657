import request from "./request";

// 获取批量指令记录列表
export const apiBatchCommandRecordsList = (data) => {
  return request({
    url: "/monitor/command/operation/cmdmodel/batch/list",
    method: "POST",
    data,
  });
};

// 查询指令模型视图
export const apiGetCommandList = (params) => {
  return request({
    url: "/monitor/command/ledger/cmdmodel/list/view",
    method: "GET",
    params,
    pending: true,
  });
};

// 批量指令下发
export const apiBatchSendCommand = (data) => {
  return request({
    url: "/monitor/command/operation/cmdmodel/batch/send",
    method: "POST",
    data,
    needJointIsolation: true,
  });
};

// 单表指令下发
export const apiOneSendCommand = (data) => {
  return request({
    url: "/monitor/command/ledger/cmdmodel/send",
    method: "POST",
    data,
  });
};

// 指令执行表具监控
export const apiBatchCommandDetailList = (data) => {
  return request({
    url: "/monitor/command/operation/cmdmodel/batch/list/detail",
    method: "POST",
    data,
  });
};

// 批量指令基本属性
export const apiGetBasicInfo = (params) => {
  return request({
    url: "/monitor/command/operation/cmdmodel/batch/list/detail/up",
    method: "GET",
    params,
  });
};

// 批量指令基本属性
export const apiReSend = (data) => {
  return request({
    url: "/monitor/command/operation/cmdmodel/batch/resend",
    method: "POST",
    data,
  });
};
