<template>
  <div ref="container" v-bind="$attrs" v-on="$listeners" v-loading="loading">
    <el-form
      ref="contentForm"
      :model="form"
      :rules="rules"
      label-width="80px"
      label-position="top"
    >
      <el-row :gutter="20" style="margin: 0 !important">
        <div class="device-dialog-content">
          <div class="top">
            <div class="title">基本属性</div>
            <div class="content">
              <el-col :span="8">
                <el-form-item
                  ref="deviceTypeId"
                  prop="deviceTypeId"
                  label="设备类型名称"
                  :rules="[
                    {
                      required: true,
                      message: '请选择设备类型名称',
                      trigger: '',
                    },
                  ]"
                >
                  <el-select
                    v-model="form.deviceTypeId"
                    placeholder="请选择设备类型"
                    filterable
                    :disabled="basicInfoDisabled"
                    @change="deviceTypeChange"
                    @visible-change="getTenantDeviceTypeList"
                  >
                    <el-option
                      v-for="option in options.deviceTypeId"
                      :key="option.value"
                      :value="option.value"
                      :label="option.label"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  v-if="form.firstCategory === '0410'"
                  ref="gasDeviceNo"
                  prop="gasDeviceNo"
                  label="条形编码"
                  :rules="[
                    {
                      required: true,
                      message: '请输入条形编码',
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-input
                    v-model="form.gasDeviceNo"
                    placeholder="请输入"
                    :disabled="basicInfoDisabled"
                    @change="handleChangeDeviceBarcode"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  v-if="form.connectType !== 2"
                  ref="deviceNo"
                  prop="deviceNo"
                  label="设备编号"
                  :rules="[
                    {
                      required: true,
                      message: '请输入设备编号',
                      trigger: 'blur',
                    },
                    {
                      max: 32,
                      message: '设备编号不可以超过32位',
                      trigger: '',
                    },
                  ]"
                >
                  <el-input
                    v-model="form.deviceNo"
                    :placeholder="
                      form.firstCategory === '0410'
                        ? '输入条形编码自动获取设备编号'
                        : '请输入'
                    "
                    :disabled="
                      basicInfoDisabled || form.firstCategory === '0410'
                    "
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  v-if="form.connectType === 2"
                  ref="deviceNo"
                  prop="deviceNo"
                  label="网关编号"
                  :rules="[
                    {
                      required: true,
                      message: '请输入网关编号',
                      trigger: '',
                    },
                    {
                      max: 32,
                      message: '网关编号不可以超过32位',
                      trigger: '',
                    },
                  ]"
                >
                  <el-autocomplete
                    v-model="form.deviceNo"
                    class="inline-input"
                    placeholder="请填写"
                    value-key="deviceNo"
                    :fetch-suggestions="queryGatewayDeviceId"
                    :trigger-on-focus="false"
                    :disabled="type !== 'add'"
                    @select="handleSelect"
                    @input="requiredInputChange($event, 'deviceNo')"
                  ></el-autocomplete>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  v-if="form.connectType === 2"
                  ref="slaveNo"
                  prop="slaveNo"
                  label="子机号"
                  :rules="[
                    {
                      required: true,
                      message: '请输入子机号',
                      trigger: 'blur',
                    },
                    {
                      max: 2,
                      message: '子机号不可以超过2位',
                      trigger: '',
                    },
                  ]"
                >
                  <el-input
                    v-model="form.slaveNo"
                    placeholder="请输入"
                    :disabled="type !== 'add'"
                    @input="requiredInputChange($event, 'slaveNo')"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  ref="manufacturerName"
                  v-if="form.manufacturerName"
                  label="厂商名称"
                >
                  <el-input v-model="form.manufacturerName" disabled></el-input>
                </el-form-item>
              </el-col>
              <template v-for="item in basicFormItems">
                <el-col
                  v-if="item.attributeKey !== 'subDeviceCount'"
                  :span="8"
                  :key="item.attributeKey"
                >
                  <el-form-item
                    :ref="item.attributeKey"
                    :prop="item.attributeKey"
                    :label="item.name"
                    :rules="item.rules"
                  >
                    <el-select
                      v-if="item.valueType === '2'"
                      v-model="form[item.attributeKey]"
                      placeholder="请选择"
                      :disabled="item.disabled || basicInfoDisabled"
                    >
                      <el-option v-if="item.needAll" :value="null"
                        >全部</el-option
                      >
                      <el-option
                        v-for="(value, key) in item.interactiveTranslationDes"
                        :key="key"
                        :value="key"
                        :label="value"
                      ></el-option>
                    </el-select>
                    <el-date-picker
                      v-else-if="item.valueType === '1'"
                      v-model="form[item.attributeKey]"
                      type="date"
                      placeholder="选择日期"
                      :disabled="item.disabled || basicInfoDisabled"
                    >
                    </el-date-picker>
                    <el-input
                      v-else
                      v-model="form[item.attributeKey]"
                      placeholder="请输入"
                      :disabled="
                        item.disabled ||
                        (basicInfoDisabled &&
                          !['ICCID', 'IMEI'].includes(item.name))
                      "
                    >
                      <i slot="suffix" v-if="item.unitname">
                        {{ item.unitname }}
                      </i>
                    </el-input>
                  </el-form-item>
                </el-col>
              </template>
            </div>
          </div>
          <div class="bottom">
            <div class="title">安装属性</div>
            <div class="content">
              <el-col :span="24">
                <el-form-item
                  ref="deviceStatus"
                  prop="deviceStatus"
                  label="是否已安装"
                  class="is-radio"
                  :rules="[
                    { required: true, message: '请选择安装状态', trigger: '' },
                  ]"
                >
                  <el-radio-group
                    v-model="form.deviceStatus"
                    :disabled="type !== 'add'"
                    @change="deviceStatusChange"
                  >
                    <el-radio
                      v-for="option in [
                        {
                          label: '未安装',
                          value: 0,
                        },
                        {
                          label: '已安装',
                          value: 1,
                        },
                      ]"
                      :key="option.label"
                      :label="option.value"
                      >{{ option.label }}</el-radio
                    >
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </div>
            <div v-if="form.deviceStatus === 1" class="content">
              <el-col :span="8">
                <el-form-item
                  ref="installDate"
                  prop="installDate"
                  label="安装日期"
                  :rules="[
                    {
                      required: form.deviceStatus == 1,
                      message: '安装日期不可为空',
                      trigger: '',
                    },
                  ]"
                >
                  <el-date-picker
                    v-model="form.installDate"
                    type="date"
                    placeholder="选择日期"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="8" v-if="siteShow(form.deviceTypeId)">
                <el-form-item
                  ref="installLocation"
                  prop="installLocation"
                  label="安装位置"
                >
                  <el-select
                    v-model="form.installLocation"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in installLocationOptionsGas"
                      :key="item.label"
                      :value="+item.value"
                      :label="item.label"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8" v-else>
                <el-form-item
                  ref="installLocation"
                  prop="installLocation"
                  label="安装位置"
                >
                  <el-cascader
                    v-model="installLocations"
                    :options="installLocationOptions"
                    :show-all-levels="false"
                    @change="installLocationChange"
                  ></el-cascader>
                </el-form-item>
              </el-col>
              <el-col :span="8" v-if="showSite">
                <el-form-item
                  ref="installLocationNo"
                  prop="installLocationNo"
                  label="站点编号"
                  :rules="[
                    {
                      max: 20,
                      message: `站点编号不可超过20个字符`,
                      trigger: '',
                    },
                  ]"
                >
                  <el-input
                    v-model="form.installLocationNo"
                    placeholder="请输入"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  ref="userNo"
                  prop="userNo"
                  label="用户号"
                  :rules="[
                    {
                      max: 128,
                      message: '用户号不可超过128个字符',
                      trigger: '',
                    },
                  ]"
                >
                  <el-input
                    v-model="form.userNo"
                    placeholder="请输入"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  ref="userType"
                  prop="userType"
                  label="客户类型"
                  :rules="[
                    {
                      required: form.deviceStatus == 1 && !specialForZhoukou,
                      message: '请选择客户类型',
                      trigger: '',
                    },
                  ]"
                >
                  <el-select
                    v-model="form.userType"
                    placeholder="请选择"
                    :disabled="specialForZhoukou"
                    @change="userTypeChange"
                  >
                    <el-option v-if="false" :value="null">全部</el-option>
                    <el-option
                      v-for="option in options.userType"
                      :key="option.defaultValue"
                      :value="+option.defaultValue"
                      :label="option.name"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  ref="username"
                  prop="username"
                  :label="showSite ? '站点名称' : '客户名称'"
                  :rules="[
                    {
                      required: form.deviceStatus == 1,
                      message: `${showSite ? '站点名称' : '客户名称'}不可为空`,
                      trigger: '',
                    },
                    {
                      max: 128,
                      message: `${
                        showSite ? '站点名称' : '客户名称'
                      }不可超过128个字符`,
                      trigger: '',
                    },
                  ]"
                >
                  <el-input
                    v-model="form.username"
                    placeholder="请输入"
                    @blur="nameOrPhone('username')"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  ref="userPhone"
                  prop="userPhone"
                  label="联系电话"
                  :rules="[
                    {
                      required:
                        form.deviceStatus == 1 &&
                        (form.userType == 2 || form.userType == 3),
                      message: '手机号不可为空',
                      trigger: '',
                    },
                    {
                      pattern: /^1[3456789]\d{9}$/,
                      message: '输入手机号格式不对，11位且13-19开头',
                      trigger: '',
                    },
                  ]"
                >
                  <el-input
                    v-model="form.userPhone"
                    placeholder="请输入"
                    @blur="nameOrPhone('userPhone')"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8" v-if="tenantType == 1">
                <el-form-item
                  ref="startMeterReading"
                  prop="startMeterReading"
                  label="初始表底"
                  :rules="[
                    {
                      required: form.deviceStatus == 1,
                      message: '初始表底不可为空',
                      trigger: '',
                    },
                  ]"
                >
                  <el-input
                    v-model="form.startMeterReading"
                    placeholder="请输入"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8" v-if="tenantType == 1">
                <el-form-item
                  ref="waterRegionCode"
                  prop="waterRegionCode"
                  label="所属区域"
                  :rules="[
                    {
                      required: true,
                      message: '请选择所属区域',
                      trigger: '',
                    },
                  ]"
                >
                  <gc-tree-select
                    v-model="form.waterRegionCode"
                    placeholder="请选择所属区域"
                    :props="{ label: 'name', id: 'code' }"
                    :options="treeOptions"
                  ></gc-tree-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item> </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item
                  ref="useGasPositon"
                  label="安装地址"
                  :required="form.deviceStatus == 1"
                >
                  <Use-Gas
                    v-loading="loadingUseGas"
                    ref="useGas"
                    :form.sync="form"
                    :required="form.deviceStatus == 1"
                    :location-options="locationOptions"
                    :loading-use-gas.sync="loadingUseGas"
                    @requestLocation="requestLocation"
                    :additionalInfo="additionalInfo"
                  ></Use-Gas>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item
                  ref="installPositon"
                  class="install-position"
                  prop="lngLat"
                  label="设备位置"
                  :rules="[
                    {
                      required: form.deviceStatus == 1 && specialForZhoukou,
                      message: '请选择设备位置',
                      trigger: '',
                    },
                  ]"
                >
                  <el-input
                    v-model="form.lngLat"
                    placeholder="经纬度信息"
                    disabled
                  ></el-input>
                  <span @click="showMap">
                    <svg class="icon" aria-hidden="true">
                      <use xlink:href="#icon-ditu"></use>
                    </svg>
                  </span>
                </el-form-item>
              </el-col>
            </div>
          </div>
        </div>
      </el-row>
    </el-form>
    <transition name="fade">
      <div class="map-box" v-if="showMapBox">
        <Map-Select
          ref="mapSelect"
          :form="form"
          @current-position="getPosition"
        ></Map-Select>
      </div>
    </transition>
  </div>
</template>

<script>
import MapSelect from "./components/MapSelect";
import UseGas from "./components/UseGas";
import {
  apiEditDeviceView,
  apiGetTenantDeviceTypeList,
  apiGetDeviceTypeList,
  apiGetGatewayList,
  apiStaffsCity,
  apiGetCityList,
  apiGetDistrictList,
  apiGetDeviceNoFromGas,
} from "@/api/account";
import { apiGetTenantConfigs } from "@/api/tenant.js";
import { isBlank } from "@/utils/validate";
import { mapActions } from "vuex";
import { siteShow } from "@/utils/business.js";

export default {
  name: "dialogConfig",
  components: { MapSelect, UseGas },
  props: {
    params: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: "add",
    },
    show: {
      type: Boolean,
      default: false,
    },
    changeType: Number,
  },
  data() {
    return {
      isZhouKou: false,
      deviceNoAndSlaveNoCopy: null,
      loading: false,
      loadingUseGas: false,
      rules: {},
      showMapBox: false,
      options: {
        deviceTypeId: [],
        userType: this.$store.getters.dataList.userType || [],
      },
      basicFormItems: [],
      dateKeyList: [],
      dynamicContentKeyList: [],
      installLocations: [], // 安装位置
      locationOptions: [],
      copyLocationOptions: [],
      additionalInfo: {},
    };
  },
  watch: {
    show: {
      immediate: true,
      handler(val) {
        if (val) {
          this.$nextTick(() => {
            apiGetTenantConfigs({ tenantId: this.tenantId }).then((res) => {
              let list = res.dataList || [];
              list.forEach((item) => {
                if (item.key === "enable_coordinate_required") {
                  //租户配置如果没有通讯记录则隐藏通讯记录tab
                  this.isZhouKou = item.value === "true";
                }
              });
            });
            this.getTenantDeviceTypeList(true);
            if (this.type !== "add") {
              this.deviceTypeChange(this.form.deviceTypeId);
            }
            if (
              this.form.userType &&
              (this.form.username || this.form.userPhone)
            ) {
              this.$refs.useGas?.getAddressList(
                this.type === "change"
                  ? { userId: this.params.userId }
                  : {
                      userType: this.form.userType,
                      username: this.form.username,
                      phone: this.form.userPhone,
                    },
                this.form.addressName || null
              );
            }
            const installLocation = this.form?.installLocation;
            if (!isBlank(installLocation)) {
              const firstValue = installLocation < 10 ? "0" : "1";
              this.installLocations = [firstValue, installLocation + ""];
            } else {
              this.installLocations = [];
            }
            // 提前备份完整网关子设备编号
            this.deviceNoAndSlaveNoCopy = this.form.deviceNo;
          });
          this.getOrgOrTenantLocation();
        }
      },
    },
    specialForZhoukou(val) {
      if (val) {
        this.$set(this.form, "userType", 1);
      } else {
        this.$set(this.form, "userType", null);
        this.$nextTick(() => {
          this.$refs.contentForm?.clearValidate(["userType"]);
        });
      }
    },
    "form.deviceStatus": {
      handler(val) {
        if (val) {
          setTimeout(() => {
            this.$refs.contentForm.clearValidate();
          });
        }
      },
    },
  },
  computed: {
    tenantId() {
      return this.$store.getters.userInfo.tenantId || null;
    },
    tenantType() {
      return this.$store.getters.userInfo.tenantType || "0";
    },
    form: {
      get() {
        return this.params;
      },
      set(val) {
        this.$emit("update:params", val);
      },
    },
    basicInfoDisabled() {
      let status = true;
      if (
        this.form.deviceStatus === 0 ||
        this.type === "add" ||
        (this.type === "change" && this.changeType === 0)
      ) {
        status = false;
      }

      return status;
    },
    // 安装位置级联下拉项
    installLocationOptions() {
      const options = [
        { value: "0", label: "户内" },
        { value: "1", label: "管网" },
      ];
      const { installLocation = [] } = this.$store.getters.dataList;
      const array = installLocation.map((item) => {
        return { value: item.defaultValue, label: item.name };
      });
      options[0].children = array.filter((item) => item.value < 10);
      options[1].children = array.filter(
        (item) => item.value >= 10 && item.value < 20
      );
      return options;
    },
    //安装位置-气体监测仪
    installLocationOptionsGas() {
      const array = (this.$store.getters.dataList.installLocation || []).map(
        (item) => {
          return {
            value: item.defaultValue,
            label: item.name,
          };
        }
      );
      return array.filter((item) => item.value >= 20);
    },
    // specialForZhoukou----周口定制
    specialForZhoukou() {
      return this.isZhouKou && this.form.deviceTypeId == 98;
    },
    // 站点编号显示条件/站点名称字段显示条件
    showSite() {
      return (
        (this.installLocations.length > 0 && this.installLocations[0] == 1) ||
        this.siteShow(this.form.deviceTypeId)
      );
    },
    treeOptions() {
      return this.$store.getters.treeOptions;
    },
  },
  methods: {
    siteShow,
    ...mapActions({
      getProvinceList: "apiCache/getProvinceList",
      getTreeOptions: "apiCache/getTreeOptions",
    }),
    async requestLocation(addressParams) {
      const addressCode = addressParams.addressCode;
      if (addressCode && addressCode[2]) {
        const provinces = await this.getProvinceList();
        const citys = (await apiGetCityList(addressCode[0])).records;
        const districts = (await apiGetDistrictList(addressCode[1])).records;
        const province = provinces.find((o) => o.regionCode === addressCode[0]);
        const city = citys.find((o) => (o.regionCode = addressCode[1]));
        this.locationOptions = [
          {
            label: province.regionName,
            value: province.regionCode,
            children: [
              {
                label: city.regionName,
                value: city.regionCode,
                children: districts.map((o) => {
                  return {
                    label: o.regionName,
                    value: o.regionCode,
                  };
                }),
              },
            ],
          },
        ];
      } else {
        this.locationOptions = this.copyLocationOptions;
        this.$set(this.form, "addressCode", [
          this.additionalInfo.province,
          this.additionalInfo.city,
          "",
        ]);
      }
    },
    getOrgOrTenantLocation() {
      apiStaffsCity({ tenantId: this.tenantId }).then((res) => {
        const additionalInfo = res.additionalInfo;
        this.additionalInfo = additionalInfo;
        apiGetDistrictList(additionalInfo.city).then((data) => {
          if (this.type === "add" || this.type === "install") {
            this.$set(this.form, "addressCode", [
              additionalInfo.province,
              additionalInfo.city,
              "",
            ]);
          }
          this.locationOptions = [
            {
              label: additionalInfo.provinceName,
              value: additionalInfo.province,
              children: [
                {
                  label: additionalInfo.cityName,
                  value: additionalInfo.city,
                  children: [
                    {
                      label: "",
                      value: "",
                    },
                    ...data.records.map((o) => {
                      return {
                        label: o.regionName,
                        value: o.regionCode,
                      };
                    }),
                  ],
                },
              ],
            },
          ];
          this.copyLocationOptions = this._.cloneDeep(this.locationOptions);
        });
      });
    },
    // 切换安装位置
    installLocationChange(e) {
      this.form.installLocation = e[1];
      if (e[1] < 10) {
        this.$set(this.form, "installLocationNo", "");
      }
    },
    requiredInputChange(e, type) {
      this.form[type] = e.replace(/[^0-9]/gi, "");
    },
    nameOrPhone() {
      const { userType, username, userPhone } = this.form;
      if (!username) return;
      this.loadingUseGas = true;
      this.$refs.useGas.getAddressList(
        this.type === "change"
          ? { userId: this.params.userId }
          : {
              userType,
              username,
              phone: userPhone,
            }
      );
    },
    queryGatewayDeviceId(query, cb) {
      this.$set(this.form, "gatewayDeviceId", null);
      apiGetGatewayList(query).then((res) => {
        let list = res || [];
        cb(list);
        if (!list.length) {
          this.$message.warning("暂无可选择的网关设备");
        }
      });
    },
    handleSelect(item) {
      this.$set(this.form, "gatewayDeviceId", item.deviceId);
    },
    userTypeChange() {
      this.$refs.contentForm.clearValidate(["username", "userPhone"]);
    },
    deviceStatusChange() {
      this.$refs.contentForm.clearValidate(["installDate"]);
      this.$refs.useGas?.$refs.useGasForm?.clearValidate();
    },
    getTenantDeviceTypeList(condition) {
      if (!condition) return;
      // 新增和待安装状态可以选择设备类型，所以只能使用已授予设备类型列表
      if (this.type === "add" || this.params?.deviceStatus == 0) {
        apiGetTenantDeviceTypeList().then((res) => {
          this.options.deviceTypeId = res.map((item) => {
            return {
              value: item.deviceTypeId,
              label: item.deviceTypeName,
            };
          });
          this.judgeCancelDeviceType();
        });
      } else {
        apiGetDeviceTypeList(this.tenantId).then((res) => {
          let list = res.records || [];
          this.options.deviceTypeId = list.map((item) => {
            return {
              value: item.deviceTypeId,
              label: item.deviceTypeName,
            };
          });
        });
      }
    },
    // 判断待安装场景设备类型被取消
    judgeCancelDeviceType() {
      const { deviceStatus, deviceTypeId } = this.params;
      // 待安装
      if (deviceStatus == 0) {
        const deviceTypeList = [...this.options.deviceTypeId];
        const target = deviceTypeList.find(
          (item) => item.value == deviceTypeId
        );
        // 授予设备类型列表未找到该设备类型
        if (!target) {
          apiGetDeviceTypeList(this.tenantId).then(({ records = [] }) => {
            const data = records.find(
              (item) => item.deviceTypeId == deviceTypeId
            );
            if (data) {
              this.options?.deviceTypeId?.push({
                value: data.deviceTypeId,
                label: data.deviceTypeName,
              });
            }
          });
        }
      }
    },
    deviceTypeChange(deviceTypeId) {
      if (!deviceTypeId) return;
      this.loading = true;
      this.$refs.contentForm.clearValidate();
      this.basicFormItems.forEach((item) => {
        this.$set(this.form, item.attributeKey, null);
      });
      this.basicFormItems = [];
      this.$emit("device-type-changed", deviceTypeId);
      // 若当前设备无站点编号则清空
      if (!this.showSite) {
        this.$set(this.form, "installLocationNo", "");
      }
      // 若当前安装位置不存在的可选范围内，则清空
      if (!isBlank(this.form.installLocation)) {
        if (this.siteShow(deviceTypeId)) {
          if (this.form.installLocation < 20) {
            this.form.installLocation = "";
            this.installLocations = [];
          }
        } else {
          if (this.form.installLocation >= 20) {
            this.form.installLocation = "";
            this.installLocations = [];
          }
        }
      }
      /**
       * 需要新增入参：operate：0-新增；1-修改；1-安装
       * deviceTypeId暂时默认为1
       */
      const operateMap = {
        add: 0, // 新增场景
        change: 1, // 安装和修改对于后端来说是同一个场景
        install: 1, // 安装和修改对于后端来说是同一个场景
      };
      let obj =
        this.type === "add"
          ? {
              deviceTypeId,
              operate: operateMap[this.type],
              isZhouKou: this.isZhouKou ? "1" : "0",
            }
          : {
              deviceTypeId,
              operate: operateMap[this.type],
              deviceId: this.params.deviceId,
              isZhouKou: this.isZhouKou ? "1" : "0",
            };
      apiEditDeviceView(obj)
        .then((res) => {
          this.dynamicContentKeyList = [];
          let libaseProductAttributesst = res.baseProductAttributes || [];
          let deviceTypeAttributes = res.deviceTypeAttributes || [];
          this.$set(this.form, "connectType", res.nodeType);
          this.$set(this.form, "firstCategory", res.firstCategory);
          if (res.nodeType == 2 && this.form.deviceNo) {
            // 网关子设备回显时，需要将返回的表号进行网关号和子机号的拆分
            let val = this.deviceNoAndSlaveNoCopy.split("$");
            this.$set(this.form, "deviceNo", val[0]);
            this.$set(this.form, "slaveNo", val[1]);
          }
          this.$set(this.form, "manufacturerId", res.manufacturerId);
          this.$set(this.form, "manufacturerName", res.manufacturerName);
          let list = [...libaseProductAttributesst, ...deviceTypeAttributes];
          this.basicFormItems = list.map((item) => {
            this.dynamicContentKeyList.push(item.attributeKey);
            let rules = [];
            if (item.inputCheck) {
              rules.push({
                pattern: item.inputCheck,
                message: `输入${item.name}格式不对`,
                trigger: "",
              });
            }
            if (item.lengthCheck) {
              rules.push({
                max: item.lengthCheck,
                message: `${item.name}不能超过${item.lengthCheck}位`,
                trigger: "",
              });
            }
            if (item.optional) {
              rules.push({
                required: true,
                message: `${item.name}不可为空`,
                trigger: "blur",
              });
            }
            if (item.defaultValue) {
              let defaultValue = item.defaultValue;
              this.$set(this.form, item.attributeKey, defaultValue);
            }
            return {
              attributeKey: item.attributeKey,
              name: item.name,
              disabled: Boolean(item.isLink),
              rules,
              valueType: item.valueType,
              unitname: item.unitname,
              interactiveTranslationDes: item.interactiveTranslationDes,
            };
          });
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
          console.log(this.dynamicContentKeyList, "this.dynamicContentKeyList");
          this.$emit("send-dynomic-keys", this.dynamicContentKeyList);
        });
    },

    /**
     * 烟灶设备设备条形码更改查询与该条形码绑定的设备编号
     * @param {*} code
     */
    async handleChangeDeviceBarcode(code) {
      const data = await apiGetDeviceNoFromGas({ deviceNo: code });
      this.$set(this.form, "deviceNo", data?.deviceMac);
    },

    showMap() {
      this.showMapBox = true;
      setTimeout(() => {
        this.$refs.mapSelect.$el.scrollIntoView(false);
      }, 600);
    },
    getPosition(obj) {
      this.$set(this.form, "lngLat", `经度${obj.lng}度，纬度${obj.lat}度`);
      this.$set(this.form, "longitude", obj.lng);
      this.$set(this.form, "latitude", obj.lat);
    },
    showValidFalse(ref) {
      if (ref === "lngLat") {
        this.showMap();
      } else {
        this.$nextTick(() => {
          const refCopy = this.$refs[ref];
          let el = Array.isArray(refCopy) ? refCopy[0]?.$el : refCopy.$el;
          el.scrollIntoView(false);
        });
      }
    },
    validateUseGas() {
      let status = true;
      this.$refs.useGas?.$refs.useGasForm.validate((valid, obj) => {
        let lackList = Object.keys(obj);
        if (lackList.length) {
          this.showValidFalse("useGasPositon");
        }
        status = valid;
      });
      return status;
    },
    resetUseGas() {
      if (this.$refs.useGas) {
        this.$refs.useGas.addressList = [];
        this.$refs.useGas.params = {};
        this.$refs.useGas.$refs.useGasForm?.resetFields();
      }
    },
  },
  created() {
    console.log(this.tenantType, "this.tenantType");
    if (this.tenantType == 1) {
      this.getTreeOptions();
    }
  },
};
</script>
<style lang="scss" scoped>
.device-dialog-content {
  display: flex;
  flex-direction: column;
  .top {
    border-bottom: 1px dashed #cccccc;
    margin-bottom: 20px;
  }
  .title {
    font-size: 14px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #ababab;
    padding-bottom: 20px;
  }

  ::v-deep .is-radio {
    display: flex;
    .el-form-item__label {
      margin-right: 24px;
    }
  }
}
.map-box {
  width: 100%;
  height: 300px;
  border: 1px solid #eee;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  height: 0;
}
::v-deep .install-position {
  .el-form-item__content {
    display: flex;
    .icon {
      display: inline-block;
      width: 32px;
      height: 32px;
      margin-left: 12px;
      cursor: pointer;
    }
  }
}
::v-deep .el-form-item--small .el-form-item__label {
  height: 32px;
}
</style>
