import { isBlank } from "@/utils/validate";
import { apiGetTimePointData } from "@/api/account";

export default {
  props: {
    tabData: Object,
    activeChart: String,
  },
  data() {
    return {
      loading: false,
      deviceDataList: [],
      echartsFlag: true,
      params: {
        time: null, //时间区间
      },
    };
  },
  computed: {
    deviceId() {
      return this.tabData?.deviceId;
    },
  },
  methods: {
    getData(flag, checked) {
      this.loading = true;
      let obj = {};
      for (var key in this.params) {
        let val = this.params[key];
        if (key === "time") {
          // 如果没有时间则默认取最近一天内
          if (isBlank(val)) {
            // 一天内
            this.params.time = [
              this.dayjs(new Date().getTime() - 3600 * 1000 * 24).format(
                "YYYY-MM-DD HH:mm:ss"
              ),
              this.dayjs().format("YYYY-MM-DD HH:mm:ss"),
            ];
          }
          obj["startTime"] = this.dayjs(this.params.time[0]).format(
            "YYYY-MM-DD HH:mm:ss"
          );
          obj["endTime"] = this.dayjs(this.params.time[1]).format(
            "YYYY-MM-DD HH:mm:ss"
          );
        } else {
          !isBlank(val) ? (obj[key] = val) : null;
        }
      }
      // 取压口（压力点）
      if (flag == "pressure") {
        obj["categoryType"] = 1;
        obj["attributeKey"] = "gasTemperature,pressure";
      } else {
        //流量计（设备）
        obj["categoryType"] = 0;
        if (checked) {
          obj["attributeKey"] = "";
          let attributeKeyArr = [];
          checked.forEach((key) => {
            if (key === "cumulativeFlow") {
              attributeKeyArr.push(
                "workCumulativeFlow",
                "standardCumulativeFlow"
              );
            } else if (key === "instantaneousFlow") {
              attributeKeyArr.push(
                "standardInstantaneousFlow",
                "workInstantaneousFlow"
              );
            } else {
              attributeKeyArr.push(key);
            }
          });
          obj["attributeKey"] = attributeKeyArr.join(",");
        }
      }
      apiGetTimePointData({
        ...obj,
        deviceId: this.deviceId,
      })
        .then((res) => {
          this.deviceDataList = res || [];
          //判断是否存在时点数据
          let statusArr = [];
          this.deviceDataList.forEach((item) => {
            Object.keys(item.status).forEach((key) => {
              statusArr.push(item.status[key]);
            });
          });

          this.echartsFlag = !statusArr.every((item) => item.length == 0);
          if (this.echartsFlag) {
            this.$nextTick(() => {
              if (flag == "pressure") {
                this.pressureTapCharts();
              }
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    reset(flag) {
      this.params.time = null;
      this.getData(flag);
    },
  },
};
