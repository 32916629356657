<template>
  <bs-adapt prop-name="screen-container" :resize-listenter="resizeListenter">
    <map-container
      slot="main"
      ref="mapContainer"
      :category-id="categoryId"
      :scale="scale"
    />
    <map-header class="z-top" />
    <map-search class="z-top" @map-focus="handleMapFocus" />
    <div class="box-select z-top">
      <span class="label">设备大类</span>
      <el-select
        popper-class="bs-select"
        v-model="categoryId"
        @change="handleSelectChange"
      >
        <el-option
          v-for="item in firstCategory"
          :key="item.productCategoryId"
          :label="item.name"
          :value="item.productCategoryId"
        >
        </el-option>
      </el-select>
    </div>
    <device-status
      class="z-top"
      ref="deviceStatus"
      :first-category="firstCategory"
    />
    <alarm-list class="z-top" ref="alarmList" @map-focus="handleMapFocus" />
    <current-alarm class="z-top" ref="currentAlarm" />

    <img class="mask top" src="@/assets/images/screen/mask-top.png" />
    <img class="mask left" src="@/assets/images/screen/mask-left.png" />
    <img class="mask right" src="@/assets/images/screen/mask-right.png" />
    <img class="mask bottom" src="@/assets/images/screen/mask-bottom.png" />
  </bs-adapt>
</template>

<script>
import BsAdapt from "./components/BsAdapt";
import MapContainer from "./mapContainer";
import MapHeader from "./header";
import MapSearch from "./search";
import currentAlarm from "./currentAlarm";
import AlarmList from "./alarmList";
import DeviceStatus from "./deviceStatus";
import { mapGetters, mapActions } from "vuex";
import { Notification } from "element-ui";

export default {
  name: "BigScreen",
  components: {
    BsAdapt,
    MapContainer,
    MapHeader,
    MapSearch,
    currentAlarm,
    AlarmList,
    DeviceStatus,
  },
  data() {
    return {
      firstCategory: [],
      categoryId: "",
      visits: 0, // 页面浏览次数
      scale: 1,
      tokenEffectTimer: null, // 每30分钟更新一次接口，刷新token
    };
  },
  computed: {
    ...mapGetters({
      websocket: "alarmPush/websocket",
      updateApiTimes: "alarmPush/updateApiTimes",
    }),
  },
  watch: {
    // 监听有告警推送时刷新大屏接口
    updateApiTimes() {
      this.updateById(this.categoryId);
    },
  },
  mounted() {
    this.getFirstCategory().then((data) => {
      this.firstCategory = [
        {
          name: "全部",
          productCategoryId: "",
        },
        ...data,
      ];
    });
    if (this.$has("monitor:run:alarm-record:select") && !this.websocket) {
      this.initWebSocket();
    }
  },
  methods: {
    ...mapActions({
      getFirstCategory: "common/getFirstCategory",
      initWebSocket: "alarmPush/initWebSocket",
      clearWebSocket: "alarmPush/clearWebSocket",
    }),
    updateById(id, update) {
      this.categoryId = id;
      if (this.visits > 1 || update) {
        this.$refs.mapContainer.getDeviceList(id);
      }
      this.$refs.deviceStatus.getDeviceStatusData(id);
      this.$refs.alarmList.getAlarmRecords(id);
      this.$refs.currentAlarm.getCurrentAlarmData(id);
    },
    handleSelectChange(id) {
      this.updateById(id, true);
    },
    handleMapFocus(detail) {
      this.$refs.mapContainer.jumpDevice(detail);
    },
    resizeListenter(scale) {
      this.scale = scale;
    },
  },
  activated() {
    Notification.closeAll();
    this.visits += 1;
    this.updateById(this.categoryId);
    this.tokenEffectTimer = setInterval(() => {
      this.$refs.deviceStatus?.getDeviceStatusData(this.categoryId);
    }, 30 * 60 * 1000);
  },
  deactivated() {
    Notification.closeAll();
    clearInterval(this.tokenEffectTimer);
  },
  beforeDestroy() {
    this.clearWebSocket();
  },
};
</script>

<style scoped lang="scss">
::v-deep {
  .gc-blank-page {
    margin-top: 50px;
    .no-data-img {
      width: initial;
      height: 100px;
    }
  }
}
.screen-container {
  width: 100vw;
  min-width: 1200px;
  height: 100vh;
  background: linear-gradient(
    180deg,
    rgba(124, 189, 255, 0.12) 0%,
    rgba(124, 189, 255, 0.12) 100%
  );
  position: relative;
  color: #fff;
}
.box-select {
  position: absolute;
  top: 97px;
  left: 10px;
  width: 22%;
  max-width: 430px;
  height: 50px;
  background: $bs-bg;
  border: 1px solid #7c9ddf;
  display: flex;
  align-items: center;
  .label {
    width: 100px;
    text-align: center;
    line-height: 19px;
    border-right: 1px solid #fff;
  }
  ::v-deep .el-select {
    height: 100%;
    & > .el-input {
      height: 100%;
      .el-input__inner {
        height: 100%;
        background: none;
        border: none;
        color: #fff;
      }
    }
  }
}
.z-top {
  z-index: 9;
}
.mask {
  z-index: 8;
  &.top {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  &.bottom {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  &.left {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
  }
  &.right {
    position: fixed;
    bottom: 0;
    right: 0;
    height: 100%;
  }
}
</style>
