<template>
  <div :id="chartId" class="liquid-fill-chart" />
</template>
<script>
const echarts = require("echarts/lib/echarts");
require("echarts/lib/component/tooltip");
require("echarts/lib/component/title");
import "echarts-liquidfill";

export default {
  name: "WaterBallChart",
  props: {
    chartId: {
      type: String,
    },
    count: {
      type: Number,
    },
  },
  watch: {
    count: {
      handler() {
        this.updateChart();
      },
      deep: true,
    },
  },
  mounted() {
    this.updateChart();
  },
  methods: {
    updateChart() {
      const chartDom = document.getElementById(this.chartId);
      const liquidFillChart = echarts.init(chartDom);
      liquidFillChart.clear();
      const optionsData = this.getOptions(this.count);
      liquidFillChart.setOption(optionsData);
      addEventListener("resize", function () {
        liquidFillChart.resize();
      });
    },
    getOptions(count) {
      const color = count ? "rgba(250,157,49, 1)" : "rgba(62,193,250, 1)";
      const colorGradient = count
        ? "linear-gradient(0deg, #bf861c 0%, #fa8f33 100%)"
        : "linear-gradient(0deg, #3ae1f5 0%, #3994d7 100%)";
      return {
        series: [
          {
            type: "liquidFill",
            radius: "90%",
            data: [
              {
                value: 0.4,
                direction: "left",
                itemStyle: {
                  normal: {
                    color: colorGradient,
                    opacity: 1,
                  },
                },
              },
              {
                value: 0.3,
                direction: "left",
                itemStyle: {
                  normal: {
                    color: colorGradient,
                    opacity: 1,
                  },
                },
              },
            ],
            backgroundStyle: {
              color: {
                type: "radial",
                x: 0.5,
                y: 0.5,
                r: 0.5,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(0,24,55, 0)",
                  },
                  {
                    offset: 0.5,
                    color: "rgba(0,24,55, 0)",
                  },
                  {
                    offset: 1,
                    color: color,
                  },
                ],
                globalCoord: false,
              },
            },
            outline: {
              borderDistance: 0,
              itemStyle: {
                borderWidth: 0,
                borderColor: {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color,
                    },
                    {
                      offset: 0.5,
                      color,
                    },
                    {
                      offset: 1,
                      color,
                    },
                  ],
                  globalCoord: false,
                },
              },
            },
            label: {
              formatter: () => count,
              color: "#fff",
              fontSize: 20,
              width: 20,
            },
          },
        ],
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.liquid-fill-chart {
  width: 70px;
  height: 70px;
}
</style>
