<template>
  <div class="chartTable">
    <div class="top">
      <div class="date-box">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-rili"></use>
        </svg>
        <el-date-picker
          v-model="queryDate"
          type="datetimerange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          align="left"
          popper-class="date-box-popper-class"
          format="yyyy/MM/dd HH:mm:ss"
          :picker-options="pickerOptions"
          :clearable="false"
        >
        </el-date-picker>
      </div>
      <el-radio-group
        v-if="showRadioGroup"
        v-model="contentType"
        @change="contentChange"
      >
        <el-radio-button label="table">
          <i class="iconfont icon-liebiao"></i>
        </el-radio-button>
        <el-radio-button label="echart">
          <i class="iconfont icon-tubiao"></i>
        </el-radio-button>
      </el-radio-group>
    </div>
    <div class="content">
      <div class="table" v-show="showTable">
        <gc-table
          ref="deviceTable"
          border
          :columns="columns"
          :table-data="tableData"
        ></gc-table>
      </div>
      <div class="echart" v-if="showEchart">
        <div id="echartBox" class="echart" v-if="tableData.length"></div>
        <gc-empty v-else></gc-empty>
      </div>
    </div>
  </div>
</template>

<script>
const echarts = require("echarts/lib/echarts");
require("echarts/lib/chart/line");
require("echarts/lib/component/grid");
require("echarts/lib/component/tooltip");
require("echarts/lib/component/visualMap");
require("echarts/lib/component/dataZoom");
require("echarts/lib/component/markLine");
require("echarts/lib/component/markPoint");

import { apiGetHistoryStatus, apiGetHistoryStatusVaried } from "@/api/account";
import { isBlank } from "@/utils/validate";
import img from "@/assets/images/icon/Polygon2.png";

export default {
  name: "chartTable",
  props: {
    openType: String,
    show: Boolean,
    info: {
      type: Object,
      default: () => {},
    },
    entry: {
      type: String,
      default: () => {
        return "list";
      },
    }, //组件入口
    defaultTime: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: "最近1小时",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近1天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近30天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
        disabledDate(time) {
          return (
            time.getTime() > Date.now() ||
            time.getTime() < Date.now() - 3600 * 1000 * 24 * 31
          );
        },
      },
      contentType: "table",
      tableData: [],
      queryDate: null,
      myChart: null,
      option: null,
      queryType: null,
      thresholdLine: [], // 后端接口返回的折线图阈值数组，用于折线图线条颜色判定
    };
  },
  computed: {
    columns() {
      return [
        {
          name: "时间",
          key: "time",
        },
        {
          name: `${this.info.name}`,
          key: "value",
          render: (h, row) => {
            return h(
              "span",
              {},
              !isBlank(row.value)
                ? row.value + (!isBlank(row.unitName) ? row.unitName : "")
                : "--"
            );
          },
        },
      ];
    },
    showTable() {
      let { timeSeriesDesc } = this.info;
      return (
        (this.contentType === "table" && this.showRadioGroup) ||
        (timeSeriesDesc?.drawType.length == 1 &&
          timeSeriesDesc?.drawType.includes("list"))
      );
    },
    showEchart() {
      let { timeSeriesDesc } = this.info;
      return (
        (this.contentType === "echart" && this.showRadioGroup) ||
        (timeSeriesDesc?.drawType.length == 1 &&
          timeSeriesDesc?.drawType.includes("lineChart"))
      );
    },
    showRadioGroup() {
      return (
        this.info.timeSeriesDesc?.drawType.length > 1 ||
        !this.info.timeSeriesDesc
      );
    },
  },
  watch: {
    queryDate(val) {
      let timeValue = val[1].getTime() - val[0].getTime();
      if (timeValue < 86400000) {
        this.queryType = 1;
      } else if (timeValue / 3600000 / 24 >= 30) {
        this.queryType = 3;
      } else {
        this.queryType = 2;
      }
      this.queryDateChange();
    },
    show(val) {
      if (!val) {
        this.showRadioGroup ? (this.contentType = "table") : null;
        this.myChart?.clear();
      }
    },
  },
  created() {
    this.resetTime();
  },
  methods: {
    contentChange(val) {
      if (val === "echart" && this.tableData.length) {
        this.initEchart();
      }
    },
    initEchart() {
      let list = this._.cloneDeep(this.tableData);
      // 判断当前列表内存在一种还是多种检测属性
      let attributeKeyNum = [];
      list.forEach((item) => {
        let findItem = attributeKeyNum.find(
          (el) => el.attributeKey === item.attributeKey
        );
        if (!findItem) {
          const { attributeKey, name, unitName } = item;
          attributeKeyNum.push({
            attributeKey,
            name,
            unitName,
          });
        }
      });
      // x轴(如果存在两种检测属性，则时间需聚合，共用X轴)
      let time = [...new Set(list.map((item) => item.time) || [])].sort(
        (a, b) => {
          return (
            new Date(this.dayjs(a).format("YYYY-MM-DD HH:mm:ss")).getTime() -
            new Date(this.dayjs(b).format("YYYY-MM-DD HH:mm:ss")).getTime()
          );
        }
      );
      // 数据点
      let seriesData = [];
      let yUnitName = "";
      let dataMaxArr = []; //数据数组
      let thresholdMaxArr = []; //阈值数组
      const seriesConfig = {
        type: "line",
        smooth: true,
        symbolSize: 10,
        symbol: "circle",
        connectNulls: true,
      };
      attributeKeyNum.forEach((item) => {
        let arr = list.filter((el) => el.attributeKey == item.attributeKey);
        let data = time.map((time) => {
          let findItem = arr.find((el) => el.time === time);
          if (findItem) {
            if (this.thresholdLine.length) {
              this.thresholdLine.forEach((t) => {
                if (!isBlank(t.gt)) {
                  thresholdMaxArr.push(t.gt);
                }
                if (!isBlank(t.gte)) {
                  thresholdMaxArr.push(t.gte);
                }
                if (!isBlank(t.lt)) {
                  thresholdMaxArr.push(t.lt);
                }
                if (!isBlank(t.lte)) {
                  thresholdMaxArr.push(t.lte);
                }
              });
              let itemStyle = {
                shadowBlur: 10,
                shadowColor: "#16A65D",
              };
              // 判断当前值属于哪个范围区间
              let color =
                (
                  this.thresholdLine.find((lineValue) => {
                    // lt 小于 lte 小于等于 gt 大于 gte 大于等于
                    if (
                      (!isBlank(lineValue.lt) || !isBlank(lineValue.lte)) &&
                      isBlank(lineValue.gt) &&
                      isBlank(lineValue.gte)
                    ) {
                      if (!isBlank(lineValue.lt)) {
                        return findItem.value < lineValue.lt;
                      }
                      if (!isBlank(lineValue.lte)) {
                        return findItem.value <= lineValue.lte;
                      }
                    } else if (
                      (!isBlank(lineValue.gt) || !isBlank(lineValue.gte)) &&
                      isBlank(lineValue.lt) &&
                      isBlank(lineValue.lte)
                    ) {
                      if (!isBlank(lineValue.gt)) {
                        return findItem.value > lineValue.gt;
                      }
                      if (!isBlank(lineValue.gte)) {
                        return findItem.value >= lineValue.gte;
                      }
                    } else {
                      return (
                        (findItem.value > lineValue.gt ||
                          findItem.value >= lineValue.gte) &&
                        (findItem.value < lineValue.lt ||
                          findItem.value <= lineValue.lte)
                      );
                    }
                  }) || {}
                ).color || "#5470c6";
              itemStyle.shadowColor = color;
              itemStyle.color = color;
              return {
                value: findItem.value,
                itemStyle,
              };
            }
            return {
              value: findItem.value,
            };
          }
          return {
            value: "",
          };
        });
        data.forEach((d) => {
          dataMaxArr.push(Number(d.value));
        });
        if (arr.length > 0) {
          seriesData.push({
            ...seriesConfig,
            showSymbol: arr.length === 1,
            data: data,
            name: item.name,
            markPoint:
              this.info.attributeKey === "pressure"
                ? {
                    data: data.map((o, index) => {
                      return {
                        coord: [index, o.value],
                        symbol: `image://${img}`,
                        symbolSize: !o.isPressHigh ? 0 : [18, 15],
                      };
                    }),
                  }
                : null,
          });
          yUnitName += isBlank(yUnitName) ? item.unitName : "/" + item.unitName;
        }
      });

      // y轴最大值
      let dataMax = dataMaxArr.length > 0 ? Math.max(...dataMaxArr) : 0;
      let thresholdMax =
        thresholdMaxArr.length > 0 ? Math.max(...thresholdMaxArr) : 0;
      let yMax = dataMax >= thresholdMax ? dataMax : thresholdMax;
      this.myChart = echarts.init(document.getElementById("echartBox"));
      this.option = {
        tooltip: {
          show: true,
          trigger: "axis",
          formatter: (params) => {
            let str = `<div>${params[0].axisValueLabel}</div>`;
            params.forEach((item) => {
              str += `
              <div>
                <span style="display:inline-block;width:10px;
                height:10px;border-radius:50%;background:${
                  item.color
                };margin-right:6px">
                </span>
                ${item.seriesName}：${item.value}${
                (
                  attributeKeyNum.find((el) => el.name === item.seriesName) ||
                  {}
                ).unitName || ""
              }
              </div>`;
            });
            return str;
          },
        },
        grid: {
          width: "auto",
          top: "30px",
          bottom: "20%",
        },
        color: ["#5470c6", "#91cc75"],
        xAxis: {
          type: "category",
          data: time,
          axisLine: {
            show: true,
            lineStyle: {
              color: "#D7D8E5",
            },
          },
          axisLabel: {
            interval: "auto", //0：表示全部显示不间隔；auto:表示自动根据刻度个数和宽度自动设置间隔个数
            rotate: 18, //-30度角倾斜显示
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          name: yUnitName,
          nameTextStyle: {
            padding: [0, 20, 0, 0],
          },
          type: "value",
          axisLine: {
            show: true,
            lineStyle: {
              color: "#D7D8E5",
            },
          },
          splitLine: {
            lineStyle: {
              type: "dashed",
            },
          },
          max: yMax, //y轴刻度最大值-如果数据小于阈值则取阈值，否则取数据
        },
        series: seriesData,
        dataZoom: [
          {
            type: "slider",
            show: true,
            bottom: "7px",
            labelFormatter: function () {
              return "";
            },
          },
        ],
      };
      if (this.thresholdLine.length) {
        this.option.visualMap = seriesData.map((item, index) => {
          return {
            seriesIndex: index,
            show: false,
            top: 0,
            orient: "horizontal",
            inverse: true,
            itemGap: 30,
            itemSymbol: "circle",
            pieces: this.thresholdLine,
          };
        });
        let markLineData = [];
        this.thresholdLine.forEach((item) => {
          if (
            (!isBlank(item.gt) && item.gt !== 0) ||
            (!isBlank(item.gte) && item.gte !== 0)
          ) {
            markLineData.push({
              yAxis: item.gt || item.gte,
              lineStyle: {
                type: "dotted",
                color: item.color,
              },
              label: {
                color: item.color,
                fontSize: "14px",
                show: true,
                padding: 4,
                // position: "insideEndBottom",
                distance: 4,
              },
            });
          }
        });
        this.option.series = seriesData.map((item) => {
          return {
            ...item,
            markLine: {
              symbol: "none",
              silent: true,
              animation: false,
              data: markLineData,
            },
          };
        });
      }
      this.myChart.clear();
      this.myChart.setOption(this.option);
      addEventListener("resize", () => {
        this.myChart.resize();
      });
    },
    resetTime() {
      let end;
      let start;
      if (this.defaultTime.length > 0) {
        this.queryDate = this.defaultTime;
      } else {
        switch (this.openType) {
          // 状态卡片处弹出来的默认查一小时前的
          case "card":
            end = new Date();
            start = new Date();
            start.setTime(start.getTime() - 3600 * 1000);
            this.queryDate = [start, end];
            break;
          // 报警器默认查半小时前的
          case "alarm":
            end = new Date(this.info.createTime);
            start = new Date(this.info.createTime);
            end.setTime(start.getTime() + 1800 * 1000);
            this.queryDate = [start, end];
            break;
          case "pressure":
            end = new Date(this.info.createTime);
            start = new Date(this.info.createTime);
            end.setTime(start.getTime() + 1000 * 60 * 60 * 12);
            start.setTime(start.getTime() - 1000 * 60 * 60 * 12);
            this.queryDate = [start, end];
            break;
        }
      }
    },
    queryDateChange() {
      let params = {
        attributeKey: this.info.attributeKey,
        deviceId: this.info.deviceId,
        startTime: this.dayjs(this.queryDate[0]).format("YYYY-MM-DD HH:mm:ss"),
        endTime: this.dayjs(this.queryDate[1]).format("YYYY-MM-DD HH:mm:ss"),
        queryType: this.queryType,
      };
      let api =
        this.entry === "detail"
          ? apiGetHistoryStatus
          : apiGetHistoryStatusVaried;
      api(params).then((res) => {
        this.tableData = res.records || [];
        this.thresholdLine = (res.thresholdLine || []).map((item) => {
          let el = JSON.parse(item);
          if (!el.color) {
            el["color"] = "#5470c6";
          }
          Object.entries(el).map(([key, value]) => {
            if (["lt", "lte", "gt", "gte"].includes(key)) {
              el[key] = Number(value);
            }
          });
          return el;
        });
        this.$nextTick(() => {
          if (this.tableData.length && this.showEchart) {
            this.initEchart();
          }
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.chartTable {
  width: 100%;
  ::v-deep .top {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 50px;
    .date-box {
      width: 320px;
      margin-right: 16px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border-radius: 4px;
      border: 1px solid #cccccc;
      .icon {
        width: 16px;
        height: 16px;
        margin-left: 10px;
      }
      .el-range-editor--small {
        border: 0;
        .el-range-separator {
          line-height: 26px;
          font-size: $base-font-size;
          color: #cccccc;
          padding: 0;
          width: 16px;
        }
        .el-range-input {
          width: calc(50% - 8px);
        }
        .el-range__close-icon,
        .el-icon-circle-close:before {
          display: none;
        }
        .el-range__icon {
          display: none;
        }
      }
    }
    .el-radio-button__inner {
      color: #4d6bff;
      border-color: #4d6bff;
      padding: 7px 15px !important;
      .el-radio-button:first-child & {
        border-color: #4d6bff;
      }
    }
    .el-radio-button__orig-radio:checked + .el-radio-button__inner {
      color: #ffffff;
      border-color: #4d6bff;
      // color: #e5662e #ffac05 #16a65d #5470c6 #91cc75;
    }
  }
  .content {
    height: 450px;
    .table,
    .echart {
      width: 100%;
      height: 100%;
    }
    .echart {
      overflow: hidden;
    }
  }
}
.date-box-popper-class {
  .el-picker-panel__sidebar {
    padding-top: 40px !important;
  }
}
::v-deep .el-table--border .el-table__cell .cell {
  padding-left: 60px;
}
</style>
