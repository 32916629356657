<template>
  <bs-box class="current-alarm">
    <bs-title title="当前告警中" />
    <ul class="items" v-if="highAlarms.length">
      <li
        class="item"
        @click="showDeviceMarks(item)"
        v-for="item in highAlarms"
        :key="item.classification"
      >
        <div class="box-ball">
          <water-ball-chart
            :chart-id="`liquidFill${item.classification}`"
            :count="item.count"
          />
        </div>
        <p class="ball-label">{{ item.alarmName }}</p>
      </li>
    </ul>
    <gc-empty v-else />
    <bs-title class="title-mgt" title="月度告警类型Top3" />
    <pie-chart v-show="alarmTypeTop3.length" :chart-data="alarmTypeTop3" />
    <gc-empty v-show="!alarmTypeTop3.length" />
    <bs-title class="title-mgt" title="设备发展量" />
    <bar-chart v-show="deviceDevelop.length" :chart-data="deviceDevelop" />
    <gc-empty v-show="!deviceDevelop.length" />
  </bs-box>
</template>

<script>
import EventBus from "@/utils/eventBus";
import BsTitle from "../components/BsTitle.vue";
import BsBox from "../components/BsBox.vue";
import WaterBallChart from "./WaterBallChart.vue";
import PieChart from "./PieChart.vue";
import BarChart from "./BarChart.vue";
import { apiHighAlarmCount, apiAlarmTop3, apiDevelopCount } from "@/api/home";

export default {
  name: "currentAlarm",
  components: { BsTitle, BsBox, WaterBallChart, PieChart, BarChart },
  data() {
    return {
      highAlarms: [],
      alarmTypeTop3: [],
      deviceDevelop: [],
    };
  },
  methods: {
    getCurrentAlarmData(firstCategory) {
      const params = {};
      if (firstCategory) params.firstCategory = firstCategory;
      apiHighAlarmCount(params)
        .then(({ records = [] }) => {
          this.highAlarms = records;
        })
        .catch((err) => {
          console.error(err);
        });
      apiAlarmTop3(params)
        .then(({ records = [] }) => {
          this.alarmTypeTop3 = records;
        })
        .catch((err) => {
          console.error(err);
        });
      apiDevelopCount(params)
        .then(({ records = [] }) => {
          this.deviceDevelop = records;
        })
        .catch((err) => {
          console.error(err);
        });
    },

    showDeviceMarks(item) {
      EventBus.$emit("render-mark", {
        id: item.classification,
        type: 1,
      });
    },
  },
};
</script>
<style scoped lang="scss">
.current-alarm {
  position: absolute;
  top: 97px;
  bottom: 10px;
  right: 10px;
  width: 22%;
  max-width: 430px;
  background: $bs-bg;
  .items {
    display: flex;
    flex-wrap: wrap;
    .item {
      margin-top: 36px;
      width: 100px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-right: calc((100% - 402px) / 3);
      &:nth-child(4n) {
        margin-right: 0;
      }
      .box-ball {
        text-align: center;
        @include flex-center;
        .icon-ball {
          height: 70px;
        }
      }
      .ball-label {
        margin-top: 20px;
        font-size: 12px;
        text-align: center;
      }
    }
  }
}
</style>
