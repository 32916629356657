<template>
  <div
    class="map-container"
    v-loading="showMask"
    element-loading-background="rgba(0, 0, 0, 0.7)"
  >
    <div id="container"></div>

    <!-- 设备详情弹框 -->
    <gc-dialog
      :width="`${scale * 88}%`"
      :show.sync="visible"
      :append-to-body="true"
      @close="visible = false"
    >
      <device-detail v-if="visible" :device-id-from-out="deviceId" />
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" plain circle @click="handleJumpDetail">
          进入系统查看
        </el-button>
        <el-button type="primary" circle @click="visible = false">
          关闭弹窗
        </el-button>
      </div>
    </gc-dialog>
  </div>
</template>
<script>
import EventBus from "@/utils/eventBus";
import AMapLoader from "@amap/amap-jsapi-loader";
import { apiHighAlarmList, apiAbnormalList } from "@/api/home";
import { apiAlarmCount, apiGetDeviceDetail } from "@/api/account";
import DeviceDetail from "@/views/deviceManage/detail/index.vue";
import { isBlank } from "@/utils/validate";
import { firstCategoryMap, deviceDetailPermissions } from "@/config/const";
import { AMapKey, AMapStyleid } from "@/config";

const iconArrow = require("@/assets/images/screen/icon-arrow.png");
const iconArrowAlarm = require("@/assets/images/screen/icon-arrow-alarm.png");

export default {
  name: "MapContainer",
  components: { DeviceDetail },
  props: {
    categoryId: {
      type: String,
      default: "",
    },
    scale: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      map: null,
      AMap: null,
      markers: [],
      markerIcon: null, // 蓝色(无告警)箭头图标
      markerIconAlarm: null, // 黄色(告警)箭头图标
      markerTip: null,
      detail: null,
      visible: false,
      highAlarmList: [],
      showMask: true,
      cluster: null,
    };
  },
  computed: {
    deviceId() {
      return this.detail?.deviceId;
    },
  },
  mounted() {
    this.initAMap();
  },
  methods: {
    // 初始化地图
    initAMap() {
      const iconWidth = 56 * this.scale;
      const iconHeight = 65 * this.scale;
      AMapLoader.load({
        key: AMapKey,
        version: "2.0",
        plugins: ["AMap.MarkerCluster"],
      })
        .then((AMap) => {
          this.map = new AMap.Map("container", {
            viewMode: "3D",
            zoom: 12,
            pitch: 60,
            dragEnable: true,
            // mapStyle: "amap://styles/darkblue",
            mapStyle: `amap://styles/${AMapStyleid}`, // 自定义地图
          });

          setTimeout(() => {
            this.showMask = false;
          }, 1000);

          this.AMap = AMap;

          // 创建 AMap.Icon 实例：
          this.markerIcon = new AMap.Icon({
            size: new AMap.Size(iconWidth, iconHeight),
            image: iconArrow,
          });
          this.markerIconAlarm = new AMap.Icon({
            size: new AMap.Size(iconWidth, iconHeight),
            image: iconArrowAlarm,
          });

          this.map.on("click", () => {
            if (this.markerTip) {
              this.markerTip.close();
            }
          });

          this.getDeviceList();
          EventBus.$on("render-mark", (e) => {
            if (this.markerTip) {
              this.markerTip.close();
            }
            this.map.remove(this.markers);
            apiAbnormalList({
              ...e,
              firstCategory: this.categoryId,
            }).then((res) => {
              this.highAlarmList = res;
              this.addCluster(res);
            });
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    // 获取高优先级告警设备列表
    getDeviceList(firstCategory) {
      // 清空标记和提示窗口
      if (this.markerTip) {
        this.markerTip.close();
      }
      this.map.remove(this.markers);
      if (this.cluster) {
        this.cluster.setMap(null);
      }
      this.markers = [];
      const params = {};
      if (firstCategory) params.firstCategory = firstCategory;
      apiHighAlarmList(params)
        .then((records = []) => {
          this.highAlarmList = records;
          this.addCluster(records);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 添加标记
    addCluster(arr) {
      const AMap = this.AMap;
      if (this.cluster) {
        this.cluster.setMap(null);
      }
      // arr.forEach((item) => {
      //   if (item.longitude) {
      //     this.markers.push(this.getGraphicMarker(item));
      //   }
      // });
      // this.map.add(this.markers);
      const points = arr.map((o) => {
        return {
          ...o,
          lnglat: [o.longitude, o.latitude],
        };
      });
      this.cluster = new AMap.MarkerCluster(this.map, points, {
        gridSize: 60, // 设置网格像素大小
        renderClusterMarker: this.renderClusterMarker, // 自定义聚合点样式
        renderMarker: this.renderMarker, // 自定义非聚合点样式
      });
      this.cluster.on("click", (e) => {
        if (e.clusterData && e.clusterData.length === 1) {
          this.jumpDevice(e.clusterData[0]);
        }
      });
      const hasPosition = arr.filter((o) => o.longitude && o.latitude);
      if (hasPosition.length) {
        this.map.setCenter(
          [hasPosition[0].longitude, hasPosition[0].latitude],
          true,
          100
        );
      }
    },
    // 获取标记内容
    getGraphicMarker(item, isJump) {
      const AMap = this.AMap;
      // 指定单个marker 标记的内容
      let marker = new AMap.Marker({
        position: new AMap.LngLat(item.longitude, item.latitude),
        icon: isJump && !item?.count ? this.markerIcon : this.markerIconAlarm,
        size: new AMap.Size(24, 24), // 图标尺寸
        offset: new AMap.Pixel(-12, -12),
      });
      marker.on("click", (e) => {
        this.jumpDevice(item, e);
      });
      return marker;
    },
    renderClusterMarker(context) {
      const AMap = this.AMap;
      var div = document.createElement("div");
      var bgColor = "radial-gradient(#ef6510 27%, #f8902e 121%)";
      var fontColor = "#fff";
      div.style.background = bgColor;
      var size = Math.round(
        30 + Math.pow(context.count / this.highAlarmList.length, 1 / 5) * 20
      );
      div.style.width = div.style.height = size + "px";
      div.style.borderRadius = size / 2 + "px";
      div.style.opacity = 0.9;
      div.innerHTML = context.count;
      div.style.lineHeight = size + "px";
      div.style.color = fontColor;
      div.style.fontSize = "14px";
      div.style.fontWeight = "bold";
      div.style.textAlign = "center";
      context.marker.setOffset(new AMap.Pixel(-size / 2, -size / 2));
      context.marker.setContent(div);
    },
    renderMarker(context) {
      const AMap = this.AMap;
      const icon = new AMap.Icon({
        size: new AMap.Size(24, 24), // 图标尺寸
        image: iconArrowAlarm, // Icon的图像
        offset: new AMap.Pixel(-12, -12),
      });
      context.marker.setIcon(icon);
    },
    // 添加并打开标记信息窗口
    addMarkerTip(item) {
      if (this.markerTip) {
        this.markerTip.close();
      }
      const AMap = this.AMap;
      this.markerTip = new AMap.InfoWindow({
        position: new AMap.LngLat(item.longitude, item.latitude),
        isCustom: true,
        content: this.getInfoWindow(item),
        anchor: "bottom-left",
        offset: new AMap.Pixel(27, -8),
      });
      this.markerTip.open(this.map);
      this.$nextTick(() => {
        document
          .querySelector("#openDetailBtn")
          .addEventListener("click", () => {
            if (!this.$has(deviceDetailPermissions)) {
              this.$message.error("您没有操作权限！");
              return;
            }
            this.visible = true;
          });
      });
    },
    // 获取信息窗口内容
    getInfoWindow(item) {
      const firstCategoryImg = firstCategoryMap[this.categoryId || "-1"]?.icon;
      const imgUrl = require(`@/assets/images/category/${firstCategoryImg}.png`);
      const template = `
        <div class="content" style="transform: scale(${
          this.scale
        }); transform-origin: 0px 100%;">
          <div class="main">
            <img class="icon" src="${imgUrl}" />
            <div class="info">
              ${
                item.count && this.$has("monitor:device:account:record:alarm")
                  ? `<div class="alarm">
                <i class="iconfont icon-caution"></i>
                <span class="count">${item.count}条告警</span>
              </div>`
                  : ""
              }
              <h2 class="title">${item.deviceTypeName || "--"}</h2>
              <p class="row">
                <span class="label">设备编号：</span>
                <span>${item.deviceNo || "--"}</span>
              </p>
              <p class="row">
                <span class="label">生产厂商：</span>
                <span>${item.manufacturerName || "--"}</span>
              </p>
              <p class="row">
                <span class="label">安装地址：</span>
                <span class="address">${
                  item.address || item.addressName || "--"
                }</span>
              </p>
            </div>
          </div>
          <div class="btn" id="openDetailBtn">放 大 设 备 详 情</div>
        </div>
      `;
      return template;
    },
    // 搜索后跳转到指定设备位置
    async jumpDevice(item) {
      let targetIndex;
      const target = this.highAlarmList.find((v, idx) => {
        if (v.deviceId === item.deviceId) {
          targetIndex = idx;
          return v;
        }
      });
      // 从告警列表跳转,缓存列表中查不到时请求详情数据
      if (item.isFromAlarmList) {
        if (target) {
          item = target;
        } else {
          const data = await apiGetDeviceDetail(item?.deviceId);
          item = { ...data, ...item };
        }
      }
      if (!item.longitude || !item.latitude) {
        this.$message.error("该设备未上传设备位置");
        return;
      }
      // 没有记录告警数量时请求
      if (isBlank(target?.count)) {
        const { count = 0 } = await apiAlarmCount(item?.deviceId);
        item.count = count;
      }
      this.detail = item;
      // 缓存列表中找不到该设备详情时,列表追加该项设备详情并在地图上添加标记
      if (isBlank(targetIndex)) {
        this.highAlarmList.push(item);
        const marker = this.getGraphicMarker(item, true);
        this.markers.push(marker);
        this.map.add(marker);
      } else {
        // 缓存列表更新设备详情信息
        this.highAlarmList[targetIndex] = item;
      }
      // 地图中心展示该设备详情
      this.map.setCenter([item.longitude, item.latitude], true, 100);
      setTimeout(() => {
        this.addMarkerTip(item);
      }, 100);
    },
    handleJumpDetail() {
      this.visible = false;
      this.$router.push(
        `/device/detail?deviceId=${this.deviceId}&deviceType=${this.detail?.deviceTypeId}`
      );
    },
  },
  beforeDestroy() {
    EventBus.$off("render-mark");
  },
};
</script>
<style scoped lang="scss">
.map-container {
  width: 100%;
  height: 100%;
  position: relative;
  #container {
    width: 100%;
    height: 100%;
    position: absolute;
    background: $bs-bg-header;
  }
}
::v-deep .amap-info-contentContainer {
  .content {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 479px;
    height: 287px;
    background: url("~@/assets/images/screen/bg-detail.png") no-repeat;
    background-size: cover;
    background-position: center;
    padding: 50px 35px 30px;
    .main {
      flex: 1;
      display: flex;
      .icon {
        width: 76px;
        height: 62px;
      }
      .info {
        flex: 1;
        margin-left: 20px;
        font-family: Lantinghei SC;
        font-weight: 600;
        position: relative;
        .alarm {
          position: absolute;
          top: 0;
          right: 0;
          color: #d81e06;
          display: flex;
          flex-direction: column;
          align-items: center;
          .iconfont {
            font-size: 20px;
          }
          .count {
            line-height: 18px;
            font-size: 12px;
          }
        }
        .title {
          font-size: 22px;
          width: 260px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .row {
          margin-top: 12px;
          opacity: 0.6;
          display: flex;
          line-height: 20px;
          &:first-of-type {
            margin-top: 17px;
          }
          .label {
            width: 70px;
            + span {
              flex: 1;
            }
          }
          .address {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
          }
        }
      }
    }
    .btn {
      @include flex-center;
      height: 34px;
      background: rgba(121, 209, 255, 0.16);
      border: 1px solid #79d1ff;
      border-radius: 3px;
      cursor: pointer;
    }
  }
}
::v-deep {
  .el-dialog {
    height: calc(100vh - 120px);
    overflow: hidden;
    border-radius: 12px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      padding: 0;
      flex: 1;
    }
    .el-dialog__footer {
      @include flex-center;
      button {
        width: 190px;
        height: 48px;
        border-radius: 24px;
        + button {
          margin-left: 16px;
        }
      }
    }
  }
}
</style>
