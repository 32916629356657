<template>
  <div class="communicaition-record" v-loading="loading">
    <div class="filter">
      <gc-custom-search
        required
        class="search"
        width="500px"
        key-word="时间区间"
        type="date"
        date-picker-type="datetimerange"
        :search.sync="params['first']"
      >
        <template v-slot:icon>
          <i class="iconfont icon-shijian"></i>
        </template>
      </gc-custom-search>
      <el-button v-click-blur type="primary" class="query" @click="query"
        >查 询</el-button
      >
      <el-button v-click-blur @click="reset">重 置</el-button>
    </div>
    <div class="table">
      <gc-table
        ref="deviceTable"
        border
        showPage
        :columns="columns"
        :table-data="tableData"
        :total="total"
        :current-page.sync="currentPage"
        @current-page-change="pageChange"
      ></gc-table>
    </div>
  </div>
</template>

<script>
import { apiGetCommunicationList } from "@/api/account";

export default {
  name: "communicaitionRecord",
  components: {},
  props: {
    curTabName: String,
    tabData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
      total: 0,
      currentPage: 0,
      columns: [
        {
          key: "communicationTime",
          name: "时间",
          width: 200,
        },
        {
          key: "communicationType",
          name: "通讯方向",
          width: 200,
        },
        {
          key: "info",
          name: "内容",
        },
      ],
      tableData: [],
      params: {
        first: null,
        current: 1,
        deviceId: null,
        size: 10,
      },
    };
  },
  watch: {
    curTabName: {
      immediate: true,
      handler(val) {
        if (val === "CommunicaitionRecord" && !this.tableData.length) {
          this.reset();
        }
      },
    },
  },
  methods: {
    getCommunicationList(page) {
      if (!this.params.first) {
        this.$message.warning("时间区间不可为空");
        return;
      }
      this.loading = true;
      let obj = {};
      this.params.deviceId = this.tabData.iotDeviceId;
      this.params.current = page;
      for (var key in this.params) {
        let val = this.params[key];
        if (val) {
          if (key === "first") {
            obj["startTime"] = this.dayjs(val[0]).format("YYYY-MM-DD HH:mm:ss");
            obj["endTime"] = this.dayjs(val[1]).format("YYYY-MM-DD HH:mm:ss");
          } else {
            obj[key] = val;
          }
        }
      }
      apiGetCommunicationList(obj)
        .then((res) => {
          this.tableData = res.records || [];
          this.total = res.total;
          this.currentPage = res.current;
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    query() {
      this.getCommunicationList(1);
    },
    reset() {
      let startTime = new Date(
        new Date(new Date().toLocaleDateString()).getTime()
      ); // 当天0点
      let endTime = new Date(
        new Date(new Date().toLocaleDateString()).getTime() +
          24 * 60 * 60 * 1000 -
          1
      ); // 当天23:59
      let ago = new Date(startTime - 86400000 * 7);
      let now = endTime;
      this.params.first = [ago, now];
      this.params.comType = null;
      this.query();
    },
    pageChange(page) {
      this.getCommunicationList(page);
    },
  },
};
</script>
<style lang="scss" scoped>
.communicaition-record {
  @include base-button(80px);
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  height: 100%;
  .filter {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 20px 0 24px;
    .query {
      margin-left: 20px;
    }
    .search {
      margin-right: 20px;
    }
  }
  .table {
    flex: 1;
    height: 0;
  }
}
</style>
