<template>
  <div class="map-box">
    <div class="map-select" id="mapSelect"></div>
    <el-autocomplete
      v-model="address"
      class="inline-input"
      placeholder="请填写"
      value-key="name"
      :fetch-suggestions="querySearch"
      :trigger-on-focus="false"
      @select="handleSelect"
    >
      <i class="el-icon-search el-input__icon" slot="suffix"> </i>
    </el-autocomplete>
  </div>
</template>

<script>
import AMapLoader from "@amap/amap-jsapi-loader";
import { AMapKey } from "@/config";

export default {
  name: "",
  components: {},
  props: {
    form: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      AMap: null,
      map: null,
      autoComplete: null,
      placeSearch: null,
      geolocation: null,
      address: null,
      lnglat: { lng: null, lat: null },
    };
  },
  mounted() {
    //DOM初始化完成进行地图初始化
    this.initMap();
  },
  methods: {
    querySearch(queryString, cb) {
      this.autoComplete.search(queryString, (status, result) => {
        let resultList = [];
        if (status === "complete") {
          resultList = result.tips;
        }
        cb(resultList);
      });
      // 调用 callback 返回建议列表的数据
    },
    handleSelect(item) {
      this.placeSearch.setCity(item.adcode);
      this.placeSearch.search(item.name, (status, result) => {
        let list = result.poiList.pois || [];
        if (list.length) {
          this.map.clearMap();
          const lng = list[0].location.lng;
          const lat = list[0].location.lat;
          const marker = new this.AMap.Marker({
            position: [lng, lat],
          });
          this.lnglat = { lng, lat };
          this.map.add(marker);
          this.map.setFitView();
        }
      });
    },
    initMap() {
      this.map = null;
      this.autoComplete = null;
      this.placeSearch = null;
      this.geolocation = null;
      this.address = null;
      AMapLoader.load({
        key: AMapKey,
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ["AMap.Geolocation", "AMap.AutoComplete", "AMap.PlaceSearch"], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {
          this.AMap = AMap;
          this.map = new AMap.Map("mapSelect", {
            zoom: 13, //初始化地图级别
          });
          //地图点击事件
          this.map.on("click", (e) => {
            this.map.clearMap();
            const lng = e.lnglat.getLng();
            const lat = e.lnglat.getLat();
            const marker = new AMap.Marker({
              position: [lng, lat],
            });
            this.lnglat = { lng, lat };
            this.map.add(marker);
            this.map.setFitView();
          });
          // 输入提示
          this.autoComplete = new AMap.AutoComplete();
          // 地点定位
          this.placeSearch = new AMap.PlaceSearch({ map: this.map });
          // 如果是回显则直接展示定位区域
          if (this.form.longitude && this.form.latitude) {
            this.map.clearMap();
            const lng = this.form.longitude;
            const lat = this.form.latitude;
            const marker = new this.AMap.Marker({
              position: [lng, lat],
            });
            this.map.add(marker);
            this.map.setFitView();
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  watch: {
    lnglat(val) {
      this.$emit("current-position", val);
    },
  },
};
</script>
<style lang="scss" scoped>
.map-box {
  position: relative;
  .map-select {
    padding: 0px;
    margin: 0px;
    width: 100%;
    height: 300px;
  }
  .inline-input {
    position: absolute;
    display: inline-block;
    width: calc(100% - 40px);
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
