<template>
  <div class="command-form">
    <el-form
      ref="commandForm"
      label-position="top"
      :model="commandForm"
      :validate-on-rule-change="false"
    >
      <el-form-item label="指令名称：">
        <div class="command-name">{{ commandForm.name }}</div>
      </el-form-item>
      <div v-for="(item, index) in innerInputData" :key="item.identifier">
        <el-form-item
          v-if="item.uiType.display"
          :class="{
            'radio-item': item.uiType.type === 'radio',
          }"
          :prop="`inputData[${index}].dataType.defaultValue`"
          :label="item.name"
          :rules="handleRules(item)"
          :ref="item.name"
        >
          <el-input
            v-if="item.uiType.type === 'inputBox'"
            v-model="item.dataType.defaultValue"
            placeholder="请输入"
          >
            <span
              slot="suffix"
              v-if="item.dataType.specs && item.dataType.specs.unitName"
              >{{ item.dataType.specs.unitName }}</span
            >
          </el-input>
          <el-radio-group
            v-model="item.dataType.defaultValue"
            v-else-if="item.uiType.type === 'radio'"
          >
            <!-- <el-radio
              v-for="(val, key, index) in handleRadioLabel(item.uiType.specs)"
              :key="index"
              :label="key"
              >{{ val }}</el-radio
            > -->
            <el-radio
              v-for="(item, index) in handleRadioLabel(item.uiType.specs)"
              :key="index"
              :label="item.value"
              >{{ item.label }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
      </div>
      <el-form-item
        v-if="commandForm.identifier === '05-remoteSecurityCheck'"
        label="指令下发："
        prop="remoteSecurityCheckRegular"
        :rules="[ruleRequired('指令下发不能为空')]"
      >
        <el-radio-group v-model="commandForm.remoteSecurityCheckRegular">
          <el-radio label="0">实时</el-radio>
          <el-radio label="1">定时</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        label="下发时间："
        v-if="
          commandForm.identifier === '05-remoteSecurityCheck' &&
          commandForm.remoteSecurityCheckRegular === '1'
        "
        prop="regularTime"
        :rules="[ruleRequired('下发时间不能为空')]"
      >
        <el-date-picker
          v-model="commandForm.regularTime"
          type="datetime"
          placeholder="选择日期时间"
          value-format="yyyy-MM-dd HH:mm:ss"
          format="yyyy-MM-dd HH:mm:ss"
        >
        </el-date-picker>
      </el-form-item>
      <!-- 关阀相关指令显示 -->
      <div class="tip-wrapper" v-if="commandForm.desc.indexOf('关阀') != -1">
        <span class="title">警示：</span>
        <span class="content">
          此关阀指令将会影响用户设备的使用，由此可能会引发民众投诉或生产事故，请谨慎处理！
        </span>
      </div>
    </el-form>
  </div>
</template>

<script>
import { ruleRequired } from "@/utils/rules.js";
import { isBlank } from "@/utils/validate";
import { cmdModelIds } from "@/config/const";

export default {
  components: {},
  name: "CommandForm",
  props: {
    commandForm: {},
  },
  data() {
    return {
      ruleRequired,
    };
  },
  computed: {
    innerInputData() {
      if (this.commandForm.identifier === "05-uploadDaysAndTimes") {
        // 设置定时上传参数
        let showCommandArr = this.commandForm.inputData.filter(
          (item) => item.uiType.display
        );
        let frequencyInput = showCommandArr[0];
        let timesInput = showCommandArr.slice(1);
        let result = [];
        if (
          !isBlank(frequencyInput.dataType.defaultValue) &&
          new RegExp(frequencyInput.dataType.reg).test(
            frequencyInput.dataType.defaultValue
          )
        ) {
          // 次数发生变化，已经填写过的值对应的输入框不再显示的话把值清空
          timesInput.map((item, index) => {
            if (
              index + 1 > frequencyInput.dataType.defaultValue &&
              !isBlank(item.dataType.defaultValue)
            ) {
              delete item.dataType.defaultValue;
            }
            return item;
          });
          // 截取对应次数的时间输入框
          result = timesInput.slice(
            0,
            Number(frequencyInput.dataType.defaultValue)
          );
        }
        return [frequencyInput, ...result];
      } else if (
        /**
         * 特殊指令交互判断：展示对应修改标识位选项
         * NBG2安全表设置持续用气参数
         * NBG2安全表设置微小流参数
         */
        cmdModelIds.includes(this.commandForm.identifier)
      ) {
        const markerBit = this.commandForm.inputData.find(
          (item) => item.identifier === "modifyMarkerBit"
        );
        const specs = markerBit?.uiType?.specs || {};
        const name = specs[markerBit.dataType.defaultValue];
        this.commandForm.inputData.forEach((data) => {
          if (Object.values(specs).includes(data.name)) {
            data.uiType.display = name === "所有参数";
          }
        });
        if (name && name !== "所有参数") {
          this.commandForm.inputData.forEach((data) => {
            if (data.name === name) {
              data.uiType.display = true;
            }
          });
        }
        return this.commandForm.inputData;
      } else {
        return this.commandForm.inputData;
      }
    },
  },
  methods: {
    // 校验条件
    handleRules(item) {
      let rules = [];
      if (item.uiType.required != false) {
        rules.push(ruleRequired(item.name + "不能为空"));
      }
      if (item.dataType.reg) {
        rules.push({
          pattern: new RegExp(item.dataType.reg),
          message: item.dataType.regMsg,
          trigger: "",
        });
      }
      return rules;
    },
    handleRadioLabel(specs) {
      let radioArr = Object.entries(specs).map(([key, value]) => {
        return {
          label: value,
          value: key,
        };
      });
      radioArr.sort(function (a, b) {
        return a.value - b.value;
      });
      return radioArr;
    },
  },
};
</script>
<style lang="scss" scoped>
.command-form {
  padding: 0 25px 0 23px;
  width: 100%;
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  ::v-deep {
    .el-form {
      .el-form-item {
        margin-bottom: 22px !important;
        .el-form-item__label {
          height: auto;
          line-height: 21px;
          word-break: break-all;
        }
        .command-name {
          padding-left: 11px;
          height: 32px;
          background: #f7f7f7;
          border-radius: 4px;
          font-size: 14px;
          color: #999999;
          line-height: 32px;
        }
      }
      .el-form-item.radio-item {
        .el-form-item__content {
          margin-top: 0;
        }
        .el-radio-group {
          display: flex;
          flex-wrap: wrap;
        }
        .el-radio {
          margin-top: 10px;
          margin-right: 0;
          width: 40%;
          display: flex;
          align-items: flex-start;
          .el-radio__label {
            display: inline-block;
            width: calc(100% - 15px);
            word-break: break-all;
            white-space: normal;
          }
        }
      }
      .tip-wrapper {
        margin-top: 10px;
        display: flex;
        align-items: flex-start;
        font-size: 12px;
        color: #ff9d57;
        line-height: 16px;
        .content {
          flex: 1;
        }
      }
      .el-form-item__label {
        padding: 0;
        height: 21px;
        font-size: 14px;
        font-weight: 600;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        color: #222222;
        line-height: 21px;
      }
      .el-form-item__content {
        margin-top: 10px;
        .el-select {
          width: 100%;
        }
        .el-date-editor.el-input,
        .el-date-editor.el-input__inner {
          width: 100%;
        }
      }
      .el-input__count {
        bottom: 0;
        right: 6px;
        background: transparent;
      }
    }
  }
}
</style>
