<template>
  <div class="channel-information">
    <div class="tabs-box">
      <div class="tabs">
        <div
          :class="['tab-item', activeChart === 'pressure' ? 'active' : '']"
          @click="changeTab('pressure')"
        >
          取压口
        </div>
        <div
          :class="['tab-item', activeChart === 'channel' ? 'active' : '']"
          @click="changeTab('channel')"
        >
          流量计
        </div>
      </div>
    </div>
    <div class="data-show">
      <!-- 取压口 -->
      <pressure-tap
        v-show="activeChart === 'pressure'"
        :curTabName="curTabName"
        :activeChart="activeChart"
        :tabData="tabData"
        ref="pressure"
      ></pressure-tap>
      <!-- 流量计 -->
      <flower-meter
        v-show="activeChart === 'channel'"
        :activeChart="activeChart"
        :tabData="tabData"
        ref="channel"
      ></flower-meter>
    </div>
  </div>
</template>

<script>
import { isBlank } from "@/utils/validate";

import PressureTap from "./PressureTap.vue";
import FlowerMeter from "./FlowerMeter.vue";

export default {
  name: "ChannelInformation",
  components: { PressureTap, FlowerMeter },
  props: {
    curTabName: String,
    tabData: Object,
  },
  data() {
    return {
      activeChart: "pressure", //取压口-pressure 流量计-channel
    };
  },
  computed: {
    relationDeviceChange() {
      return this.$store.getters["account/relationDeviceChange"];
    },
  },
  watch: {
    curTabName: {
      immediate: true,
      handler(val) {
        if (val === "ChannelInformation") {
          if (this.relationDeviceChange) {
            this.activeChart = "pressure";
            this.$refs.pressure.params.time = null;
            this.$refs.channel.params.time = null;
            this.$store.commit("account/SET_RELATION_DEVICE_CHANGE", false);
          }
        }
      },
    },
  },
  mounted() {},
  methods: {
    isBlank,
    // tab切换
    changeTab(value) {
      this.activeChart = value;
    },
  },
};
</script>
<style lang="scss" scoped>
.channel-information {
  padding: 24px;
  height: 100%;
  display: flex;
  flex-direction: column;
  .tabs-box {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
    .tabs {
      display: flex;
      .tab-item {
        width: 166px;
        height: 40px;
        line-height: 40px;
        border-radius: 8px;
        background: #f0f0f0;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #666666;
        cursor: pointer;
        &.active {
          color: #4d6bff;
          background: #ebedfa;
        }
      }
      .tab-item + .tab-item {
        margin-left: 20px;
      }
    }
  }
  .data-show {
    flex: 1;
    height: 0;
    ::v-deep {
      .pressure-tap,
      .flow-meter {
        height: 100%;
        display: flex;
      }
      .left-box {
        width: 322px;
        height: 100%;
        flex-shrink: 0;
        border-right: 0.5px dashed #999999;
        overflow: auto;
        padding-right: 11px;
        .device-item {
          &-name {
            font-size: 16px;
            line-height: 24px;
            font-weight: 600;
            padding-bottom: 10px;
          }
          &-info {
            display: flex;
            flex-wrap: wrap;
            .label-value {
              width: 50%;
              padding-top: 10px;
              font-size: 14px;
              line-height: 21px;
              .label {
                color: #666666;
              }
              .value {
                color: #333333;
              }
            }
            .label-value.row {
              width: 100%;
            }
          }
        }
        .device-item + .device-item {
          padding-top: 40px;
        }
      }
      .flow-meter-left {
        &-title {
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          color: #333;
          padding-bottom: 20px;
        }
        .flow-meter-left-search {
          width: 100% !important;
        }
        .device-item {
          padding-top: 10px;
        }
      }
      .right-box {
        padding-left: 21px;
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow: auto;
        &-title {
          display: flex;
          align-items: center;
          .title-text {
            font-size: 16px;
            line-height: 32px;
            font-weight: 600;
          }
          .list-chart {
            padding-left: 16px;
            span {
              padding: 4px 8px;
              border: 1px solid #6783ff;
              color: #4d6bff;
              cursor: pointer;
            }
            span:nth-child(1) {
              border-top-left-radius: 4px;
              border-bottom-left-radius: 4px;
            }
            span:nth-child(2) {
              border-left: none;
              border-top-right-radius: 4px;
              border-bottom-right-radius: 4px;
            }
            .active {
              background: #6783ff;
              color: #fff;
            }
          }
        }
        .pressure-tap-search {
          display: flex;
          padding-bottom: 20px;
          padding-top: 20px;
          .ops-btn {
            padding-left: 20px;
            @include base-button(80px);
          }
        }
        .pressure-tap-echarts-box {
          flex: 1;
          width: 100%;
          overflow: auto;
        }
      }
    }
  }
}
</style>
